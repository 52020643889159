import { xlRound } from 'layouts/linelist/XL_Calculation';
import { checkActionItemAssigned, checkHeihlight, executeDataVerification, isEditable, toastMessage } from 'layouts/linelist/XL_Utils';
import { compareAndHighLightDataChanges } from 'layouts/linelist/getChangesComman';
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomAgGrid from '../CustomAgGrid';
import { setHighlightByTblName } from 'layouts/linelist/getChangesComman';

const tbl_name = "xl_faherty_sizing";
const rng_name = "Sizing_Rng";

const SizingTab = forwardRef(({ setCurrentGridApi, settimeLineDataFun, setActionItemDataFun, clearFilterBtnCall }, ref) => {
	const dispatch = useDispatch();
	// let allCol = useSelector(s => s.testColLst[0][tbl_name]);
	const FormulaCalcColLst = useSelector(s => s.formulaCalcColLst[0][tbl_name]);
	const [isGridReady, setIsGridReady] = useState(false);
	const SizingData = useSelector(state => state.SizingData);
	const SSNData = useSelector((s) => s.SizingDataSSN);

	const SizingHighlistCell = useSelector(state => state.SizingHighlistCell);
	const tmpColDef = useSelector(state => state.SizingColDef ? state.SizingColDef : []);

	const columnGrpPref = useSelector((s) => s.SizingGrpColPref);
	const columnPref = useSelector((s) => s.SizingColPref);

	const SizingSorting = useSelector(state => state.SizingSorting ? state.SizingSorting : []);
	const SizingFilters = useSelector(state => state.SizingFilters ? state.SizingFilters : {});
	const refDeliveryMaster = useSelector(state => state.DeliveryMasterData);
	const refDeliveryMasterChanges = useSelector(state => state.DeliveryMasterHighlistCell);
	const refLinelistData = useSelector(state => state.LinelistData);
	const refLinelistDataChanges = useSelector(state => state.LineListHighlistCell);

	const [autoChangeState, setAutoChangeState] = useState(false)
	const [autoGetChangeResult, setAutoGetChangeResult] = useState({});

	const highlightCells = useRef([]);
	const gridApi = useRef({});
	const gridColumnApi = useRef({});
	const cellValueChanged = useRef([]);

	const setResetFilter = (setReset) => {
		let sorting = SizingSorting
		let filtering = SizingFilters
		if (setReset === "reset") {
			sorting = null
			filtering = null
		}
		gridApi.current.applyColumnState({ state: sorting })
		gridApi.current.setFilterModel(filtering);
	}
	//Required for all tabs (For before send data functionalities)
	useImperativeHandle(ref, () => ({
		validateSendData: () => {
			if (!SizingData) return false
			const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current);
			if (res.status === false) {
				highlightCells.current = res.highlightCells;
				setHighlightByTblName(tbl_name, highlightCells.current);
				return (res)
			}
			cellValueChanged.current = [];
			removeHeihlight();
			return ({ status: true })
		},
		getChangesFunc: async (masterData, metaData) => {
			const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, tmpColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
			if (res.status) {
				highlightCells.current = res.highlightCells
				dispatch({ type: "SET_SIZING_DATA", data: res.data });
				gridApi.current.refreshCells({ force: true })
			} else {
				toastMessage(res.msg, "warning");
			}
			return res
		},
		getAutoChanges: (data, downloadType) => {
			setResetFilter("reset")
			const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
			setAutoChangeState(true);
			setAutoGetChangeResult(res);
		}
	}));
	const removeHeihlight = () => {
		highlightCells.current = [];
		if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true });
	};

	useEffect(() => {
		if (isGridReady) {
			if (SizingSorting !== null && SizingSorting !== undefined) {
				gridApi.current.applyColumnState({
					state: SizingSorting
				})
			}
			if (SizingFilters !== null && SizingFilters !== undefined) {
				gridApi.current.setFilterModel(SizingFilters);
			}
		}
	}, [isGridReady])

	useEffect(() => {
		if (!refDeliveryMaster || Object.keys(gridApi.current).length <= 0) return
		if (!refDeliveryMasterChanges || refDeliveryMasterChanges.length <= 0) return
		setResetFilter("reset")
		const changes = []
		const columns = []
		refDeliveryMasterChanges.map((element) => {
			const keys = Object.keys(element);
			const key = keys[0]
			const tmp = key.split("|")
			const dt = refDeliveryMaster.filter((item) => item.row_id === tmp[2])
			changes.push(dt[0])
			columns.push(tmp[1])
		})
		const arr = highlightCells.current
		gridApi.current.forEachNode((event) => {
			const row_id = event.data.row_id
			if (fxcalc("Delivery Date/In DC")) {
				if (columns.includes("Delivery Date/ In DC")) {
					if (event.data["Delivery Month"] !== null && event.data["Delivery Month"] !== undefined) {
						const ch = changes.filter((element) => (element["Month"] || "").toLowerCase() === (event.data["Delivery Month"] || "").toLowerCase() &&
							element["Season Code"].toLowerCase() === event.data["Season Code"].toLowerCase())
						if (ch.length > 0) {
							event.data["Delivery Date/In DC"] = calculateDeliveryInDC(event);
							arr.push({ [tbl_name + "|Delivery Date/In DC|" + row_id]: 1 })
						}
					}
				}
			}
		})
		setResetFilter("set")
		highlightCells.current = arr
		gridApi.current.refreshCells({ force: true });
	}, [refDeliveryMaster, refDeliveryMasterChanges])
	useEffect(() => {
		if (!refLinelistData || Object.keys(gridApi.current).length <= 0) return
		if (!refLinelistDataChanges || refLinelistDataChanges.length <= 0) return
		setResetFilter("reset")
		const arr = highlightCells.current
		const changes = []
		const columns = []
		refLinelistDataChanges.map((element) => {
			const keys = Object.keys(element);
			const key = keys[0]
			const tmp = key.split("|")
			const dt = refLinelistData.filter((item) => item.row_id === tmp[2])
			changes.push(dt[0])
			columns.push(tmp[1])
		})
		gridApi.current.forEachNode((event) => {
			const row_id = event.data.row_id
			if (fxcalc("Production Approval Status")) {
				if (columns.includes("Production Lock") || columns.includes("DTC Confirmation") || columns.includes("Wholesale Confirmation") || columns.includes("Change Flag")) {
					const data = event.data;
					const ch = changes.filter((element) => element["Season Code"].toLowerCase() === data["Season Code"] &&
						element["SKU"].toLowerCase() === data["SKU"].toLowerCase() &&
						(element["Delivery Month"] || "").toLowerCase() === (data["Delivery Month"] || "").toLowerCase())
					if (ch.length > 0) {
						event.data["Production Approval Status"] = calculateProductionApprovalStatus(event);
						arr.push({ [tbl_name + "|Production Approval Status|" + row_id]: 1 })
					}
				}
			}
		})
		setResetFilter("set")
		highlightCells.current = arr
		gridApi.current.refreshCells({ force: true });
	}, [refLinelistData, refLinelistDataChanges])

	const onGridReady = (params) => {
		gridApi.current = params.api
		gridColumnApi.current = params.columnApi;
		setCurrentGridApi(params)
		calculateAllFormulas();

		setIsGridReady(true)
	}

	const calculateDeliveryInDC = (event) => {
		let resultVal = "";
		const SeasonCode = (event.data["Season Code"] || "").trim().toLowerCase();
		const Delivery = (event.data["Delivery"] || "").trim().toLowerCase();
		if (SeasonCode !== "" && Delivery !== "") {
			let filtredData = refDeliveryMaster;
			filtredData = filtredData.filter((i) => (`${i["Season Code"]}${i["Month"]}`).trim().toLowerCase() === (SeasonCode + Delivery));
			if (filtredData.length > 0) resultVal = (filtredData[0]["Delivery Date/ In DC"] || "");
		}
		return resultVal;
	}
	const calculateLineTotalUnits = (event) => {
		let size_arr = ["2T", "3T", "4T", "5", "6", "8", "10", "OS", "XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL", "XS/S", "S/M", "M/L", "L/XL", "MT", "LT", "XLT", "XXLT", "00_", "0", "2", "4", "6_", "8_", "10_", "12", "14", "16", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "38", "40", "42", "30T", "32T", "34T", "36T", "38T", "40T", "28WX30L", "29WX30L", "30WX30L", "31WX30L", "32WX30L", "33WX30L", "34WX30L", "35WX30L", "36WX30L", "38WX30L", "40WX30L", "42WX30L", "28WX32L", "29WX32L", "30WX32L", "31WX32L", "32WX32L", "33WX32L", "34WX32L", "35WX32L", "36WX32L", "38WX32L", "40WX32L", "42WX32L", "28WX34L", "29WX34L", "30WX34L", "31WX34L", "32WX34L", "33WX34L", "34WX34L", "35WX34L", "36WX34L", "38WX34L", "40WX34L", "42WX34L", "1XL", "2XL", "3XL", "4XL", "5XL", "1XLT", "2XLT", "3XLT", "4XLT", "40W36L", "42W32L", "42W34L", "44W30L", "44W32L", "44W34L", "46W30L", "46W32L", "48W30L", "48W32L", "50W30L", "52W30L", "36_", "38_", "40_", "42_", "44_", "46_", "48_", "50_"]
		let LineTotalUnits = 0;
		for (let i = 0; i < size_arr.length; i++) {
			LineTotalUnits += parseFloat(event.data[size_arr[i]] || 0);
		}
		return xlRound(LineTotalUnits, 0);
	};
	const calculateVariance = (event) => {
		const BuySheetTotal = parseFloat(event.data["Buy Sheet Total"] || 0);
		const SKU = (event.data['SKU'] || "").trim().toLowerCase();
		const Location = (event.data['Location'] || "").trim().toLowerCase();
		let LineTotalUnits = 0;
		SizingData.map((i) => {
			if ((`${i["SKU"]}${i["Location"]}`).trim().toLowerCase() === (SKU + Location)) {
				LineTotalUnits += parseFloat(i["Line Total Units"] || 0)
			}
		});
		return xlRound(BuySheetTotal - LineTotalUnits, 0);
	};
	const calculateProductionApprovalStatus = (event) => {
		let isApproved = false;
		const SeasonCode = (event.data['Season Code'] || "").toUpperCase();
		const SKU = (event.data['SKU'] || "").toUpperCase();
		const DeliveryMonth = (event.data['Delivery Month'] || "").toUpperCase();
		refLinelistData.map((i) => {
			if ((i["Season Code"] || "").toUpperCase() === SeasonCode
				&& (i["SKU"] || "").toUpperCase() === SKU
				&& (i["Change Flag"] || "") === ""
				&& (i["Wholesale Confirmation"] || "").toUpperCase() === "CONFIRMED"
				&& (i["DTC Confirmation"] || "").toUpperCase() === "CONFIRMED"
				&& (i["Delivery Month"] || "").toUpperCase() === DeliveryMonth
				&& (i["Production Lock"] || "").toUpperCase() === "LOCKED"
			) isApproved = true
		});
		return isApproved ? "Approved" : "Pending Approval";
	};
	const calculateApplySizingFormula = (event) => {
		let ApplySizingFormula = "YES"
		const SizingSplit = (event.data["Sizing Split"] || "").toUpperCase();
		const SizingOverride = (event.data["Sizing Override"] || "").toUpperCase();
		if (SizingSplit === "YES" || SizingOverride === "YES") ApplySizingFormula = "NO";
		return ApplySizingFormula;
	};

	const fxcalc = (colnm) => {
		let retval = false;
		if (FormulaCalcColLst.includes(colnm)) retval = true;
		return retval;
	};
	const calculateAllFormulas = () => {
		if (FormulaCalcColLst.length > 0 && sessionStorage.getItem("user_type") !== "read-only") {
			gridApi.current.forEachNode((event) => { calculateReactFormula(event) });
			gridApi.current.refreshCells({ force: true });
		}
		dispatch({ type: "SET_PLEASE_WAIT", data: false });
	};
	const calculateReactFormula = (event) => {
		fxcalc("Delivery In DC") ? event.data["Delivery In DC"] = calculateDeliveryInDC(event) : null;
		fxcalc("Line Total Units") ? event.data["Line Total Units"] = calculateLineTotalUnits(event) : null;
		fxcalc("Variance") ? event.data["Variance"] = calculateVariance(event) : null;
		fxcalc("Production Approval Status") ? event.data["Production Approval Status"] = calculateProductionApprovalStatus(event) : null;
		fxcalc("Apply Sizing Formula") ? event.data["Apply Sizing Formula"] = calculateApplySizingFormula(event) : null;
	};

	const onSortChanged = (event) => {
		const colState = gridApi.current.getColumnState();
		const savedSortState = colState
			.filter(function (s) {
				return s.sort != null;
			})
			.map(function (s) {
				return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
			});
		dispatch({ type: "SET_SIZING_SORTING", data: savedSortState });
	}
	const onFilterChanged = (event) => {
		const savedFilterModel = gridApi.current.getFilterModel();
		dispatch({ type: "SET_SIZING_FILTERS", data: savedFilterModel });
	}

	const resteAutoChangeState = () => {
		setResetFilter("set");
		setAutoChangeState(false);
		setAutoGetChangeResult({});
	}
	const setCellValueChanged = (data) => {
		cellValueChanged.current = data;
	}
	return (
		<CustomAgGrid
			setCurrentGridApi={onGridReady}
			filters={SizingFilters}
			setActionItemDataFun={setActionItemDataFun}
			tab_name="Sizing"
			tbl_name={tbl_name}
			columnDefs={tmpColDef}
			rowData={SizingData}
			SSNData={SSNData}
			tblHighlightCells={SizingHighlistCell}
			onFilterChanged={onFilterChanged}
			onSortChanged={onSortChanged}
			autoChangeState={autoChangeState}
			resteAutoChangeState={resteAutoChangeState}
			autoGetChangeResult={autoGetChangeResult}
			setCellValueChanged={setCellValueChanged}
			calculateFormula={calculateReactFormula}
			isGrouppedHeader={true}
			columnGrpPref={columnGrpPref}
			columnPref={columnPref}
		/>
	)
})
SizingTab.propTypes = {
	setCurrentGridApi: PropTypes.func.isRequired,
	settimeLineDataFun: PropTypes.func.isRequired,
};
export default SizingTab;
// 882