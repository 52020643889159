import { Box, CircularProgress, Divider, Grid, IconButton, Input, List, ListItemButton, ListItemText, Paper, Slider, Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import { toastMessage } from 'layouts/linelist/XL_Utils';
import { useEffect, useState } from 'react';
import { restApiPath } from 'utils/app.props';

import _ from 'lodash';
import CollapsibleView from '../CollaspibleView';
import LabelValuePair from '../LabelValuePair';
import ListHistoricData from '../ListHistoricData';
import { getStateDataByTblName } from 'layouts/linelist/getChangesComman';
import { calculateCurrentFOB, calculateLDP, calculateRTLIMU, calculateWHLSIMU } from '../../LinelistTab/linelistFormulas';
import { convertIntoPercentage, convertToNumber } from '../../SummaryReportTab/prepareSummaryReportData';
import { ContentCopy } from '@mui/icons-material';

const HistoricDataPanel = ({ focusedCell, focusedCellEvent, onSelectValue, columnName, tbl_name }) => {
    const [historicData, setHistoricData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);
    const [marks, setMarks] = useState();
    const [value, setVal] = useState();
    const [stepper, setStepper] = useState(1);

    const [impactView, setImpactViewData] = useState([]);

    const stateData = getStateDataByTblName(tbl_name);

    const fetchOptions = async () => {
        if (!focusedCellEvent) return;
        const rowData = focusedCellEvent.api.getDisplayedRowAtIndex(focusedCellEvent.rowIndex).data;
        const columnDef = focusedCellEvent.column.colDef;

        setHistoricData([]);
        setLoading(true);
        // // Check if the data is already cached
        // if (localStorage.getItem(cacheKey)) {
        //     const data = JSON.parse(localStorage.getItem(cacheKey));
        //     const columnName = props.colDef?.headerName;

        //     if (data && data[columnName]) {
        //         const fetchedOptions = data[columnName];
        //         if (fetchedOptions) setOptions(fetchedOptions);
        //     }
        //     setLoading(false);
        //     return;
        // }

        const url1 = `${restApiPath}callPGFunction`;
        const jsonReq = {
            "param_body": {
                "user_group_val": sessionStorage.getItem("user_type"),
                "data_table_name": tbl_name,
                "Season Code": rowData["Season Code"],
                "Department": rowData["Department"],
                "Category": rowData["Category"],
                "Subcategory": rowData["Subcategory"],
                "Style Description": rowData["Style Description"],
                "col_nm_param": columnName
            },
            "param_function": "xl_fb_get_suggestion_data_value"
        };

        const requestbody = {
            method: "POST",
            headers: {
                'Authorization': 'bearer ' + sessionStorage.getItem('access_token'),
                'Accept': "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(jsonReq),
        };
        const response = await fetch(url1, requestbody);
        const result = await response.json();

        const jsonString = result.data || "";
        if (jsonString) {
            const data = JSON.parse(jsonString);
            if (data && Object.keys(data).length > 0) {
                let hData = data[columnName];
                if (hData) {

                    //set data format as per column format
                    if (columnDef && columnDef.type === "percentage") {
                        hData = hData.map((dt) => convertIntoPercentage(dt));
                    } else
                        hData = hData.map((dt) => convertToNumber(dt));
                    setHistoricData(hData);

                    //find avg
                    const avg = _.round(_.mean(hData), 2);;
                    // console.log("avg", avg);

                    const per = avg * 0.10;
                    const calcMini = Number(parseFloat(avg - per).toFixed(2));
                    const calcMax = Number(parseFloat(avg + per).toFixed(2));
                    const stepper = (calcMax - calcMini) / 10;

                    const marks = [
                        {
                            value: calcMini,
                            label: '',
                        },
                        {
                            value: calcMax,
                            label: '',
                        },
                    ];

                    setStepper(stepper);
                    setVal(avg);
                    setMarks(marks);
                    setMinValue(calcMini);
                    setMaxValue(calcMax);
                }
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        if (focusedCell) fetchOptions();
    }, [focusedCell]);

    //calculate values for imapct view
    useEffect(() => {
        if (value && columnName === "Final FOB" && focusedCell) {

            //calculate impact view columns
            // const columnsDependancy = ["row_id", "Final FOB", "RTL IMU%", "WHSL IMU%", "MSRP", "LDP", "Current FOB", "Blended Freight", "Duty Total", "Agent Commission Rate"];
            const rowData = focusedCellEvent.api.getDisplayedRowAtIndex(focusedCellEvent.rowIndex).data;
            const tmp = [...stateData];
            const filterData = tmp.find((dt) => dt["row_id"] === rowData["row_id"]);

            //calculate Current FOB
            filterData["Final FOB"] = value;
            const currentFob = calculateCurrentFOB(filterData);
            filterData["Current FOB"] = currentFob;

            const ldp = calculateLDP(filterData);
            filterData["LDP"] = ldp;

            const rtlImu = calculateRTLIMU(filterData);
            const formatRtlImu = parseFloat(rtlImu * 100).toFixed(2) + "%";

            const whslImu = calculateWHLSIMU(filterData);
            const formatWhslImu = parseFloat(whslImu * 100).toFixed(2) + "%";

            const dt = [
                { label: "RTL IMU%", value: formatRtlImu },
                { label: "WHSL IMU%", value: formatWhslImu }
            ]
            setImpactViewData(dt);
        }
    }, [value])
    const handleSliderChange = (event, newValue) => {
        setVal(newValue);
    }

    const handleInputChange = (event) => {
        let eValue = event.target.value;
        if (eValue === "") eValue = 0;
        const columnDef = focusedCellEvent?.column.colDef;

        if (!eValue.includes(".")) {
            let val = parseFloat(eValue);
            if (columnDef && columnDef.type === "percentage")
                val = convertIntoPercentage(val);

            if (isNaN(val)) {
                toastMessage("Please enter numeric value.", "error");
                return;
            }
            setVal(val);
        } else setVal(eValue);
    }

    const adoptValue = () => {
        let val = value;
        const columnDef = focusedCellEvent?.column.colDef;
        if (columnDef && columnDef.type === "percentage")
            val = val / 100;

        onSelectValue(val);
    }

    const copyValue = () => {
        navigator.clipboard.writeText(value).then(() => {
            toastMessage("Value Copied!!", "success")
        }).catch(err => {
            console.error("Failed to copy text: ", err);
        });
    }

    if (!focusedCell && !loading) {
        return (
            <Paper
                elevation={3}
                sx={{
                    height: '100%',
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#f9f9f9',
                }}
            >
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                        //   fontWeight: 'bold',
                        borderBottom: '2px solid #3f51b5',
                        paddingBottom: 1,
                        marginBottom: 2,
                        fontSize: '12px', // Reduced font size for title
                        textAlign: "center"
                    }}
                >
                    AI Suggestions
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '11px', color: "black", fontWeight: "400" }}>Select a cell to view suggestion</Typography>
            </Paper>
        );
    }
    return (
        <Paper
            elevation={3}
            sx={{
                height: '100%',
                padding: 1,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#f9f9f9',
            }}
        >
            <Typography
                variant="h6"
                gutterBottom
                sx={{
                    //   fontWeight: 'bold',
                    borderBottom: '2px solid #3f51b5',
                    paddingBottom: 1,
                    marginBottom: 2,
                    fontSize: '12px', // Reduced font size for title
                    textAlign: "center"
                }}
            >
                AI Suggestions
            </Typography>

            {loading ? (
                <Box display="flex" justifyContent="center" mt={1}>
                    <CircularProgress size="1rem" color='info' />
                </Box>
            ) : (
                <Box mt={1}>
                    {
                        historicData.length > 0 ? (
                            <Box >
                                <Grid container spacing={2}>
                                    <Grid item xs={9} >
                                        <Box 
                                            justifyContent={"space-between"}
                                            sx={{
                                                backgroundColor: "paper",
                                                border: 1, 
                                                borderColor: 'rgba(0, 0, 0, .07)', 
                                                verticalAlign: "center", 
                                                padding: 1
                                            }}
                                        >
                                            <Input
                                                value={value}
                                                size="small"
                                                onChange={handleInputChange}
                                                // onBlur={handleBlur}
                                                alignItems={"center"}
                                                sx={{
                                                    fontSize: "13px",
                                                    width: "76%",
                                                    marginLeft: "5px",
                                                    '& .MuiInputBase-input':{
                                                        backgroundColor: "white",
                                                        textAlign: "center"
                                                    }
                                                }}
                                            />
                                            <IconButton
                                                size='small'
                                                onClick={copyValue}
                                                sx={{
                                                    marginLeft: "10px",
                                                    width: '23px',
                                                    height: '23px',
                                                    borderRadius: '4px', // Optional: Slight rounding for a clean square look
                                                    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.4)',
                                                    '&:hover': {
                                                        boxShadow: '0px 5px 8px rgba(0, 0, 0, 0.3)'
                                                    }
                                                }}
                                            >
                                                <ContentCopy sx={{ width: "15px", height: "15px" }} />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3} display="flex" justifyContent="flex-end" alignItems={"center"}>
                                        <MDButton onClick={adoptValue} sx={{ textTransform: "none", marginRight: "5px",  }} size="small" variant="gradient" color="info">Adopt</MDButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Slider
                                            marks={marks}
                                            step={stepper}
                                            aria-label='Default'
                                            value={value}
                                            valueLabelDisplay="off"
                                            min={minValue}
                                            max={maxValue}
                                            size="medium"
                                            sx={{
                                                width: '100%',
                                                '& .MuiSlider-thumb': {
                                                    height: 20,
                                                    width: 20,
                                                },
                                                '& .MuiSlider-track': {
                                                    height: 10
                                                },
                                                '& .MuiSlider-rail': {
                                                    height: 10
                                                }
                                            }}
                                            onChange={handleSliderChange}
                                        />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography
                                                variant="body2"
                                                onClick={() => setVal(minValue)}
                                                sx={{ cursor: 'pointer', fontSize: "10px" }}
                                            >
                                                {minValue}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                onClick={() => setVal(maxValue)}
                                                sx={{ cursor: 'pointer', fontSize: "10px" }}
                                            >
                                                {maxValue}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <CollapsibleView title="Category Range">
                                    <ListHistoricData historicData={historicData} setVal={setVal} />
                                </CollapsibleView>
                                {
                                    columnName === "Final FOB" ? (<>
                                        <Divider />
                                        <CollapsibleView title="Impact">
                                            {impactView.map((item, index) => (
                                                <LabelValuePair key={index} label={item.label} value={item.value} />
                                            ))}
                                        </CollapsibleView>
                                    </>
                                    ) : null
                                }
                            </Box>
                        ) : (
                            <Typography variant="body2" align="center" sx={{ fontSize: '11px', color: "black", fontWeight: "400" }}>
                                No Category Range Data Available
                            </Typography>
                        )}
                </Box>
            )}
        </Paper>
    );
};

export default HistoricDataPanel;
