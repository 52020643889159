
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { colGrpLinelist } from "layouts/linelist/colGrpVariable";
import { compareAndHighLightDataChanges, GetAutoChanges, setHighlightByTblName } from "layouts/linelist/getChangesComman";
import { xlMround, xlRound, xlXlookup } from "layouts/linelist/XL_Calculation";
import {
  CH1,
  checkActionItemAssigned,
  checkHeihlight,
  executeDataVerification,
  generateColumnGrouping,
  getColumnStyle,
  getCurrentDateOnlyAsYyyyMmDd,
  isEditable,
  JsDateToExcelDateNumber,
  toastMessage,
} from "layouts/linelist/XL_Utils";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Charts from "../Charts";
import CustomAgGrid from "../CustomAgGrid";

const tbl_name = "xl_faherty_linelist";
const rng_name = "Linelist_Rng";

const LinelistTab = forwardRef(({ setCurrentGridApi, setActionItemDataFun }, ref) => {
  const dispatch = useDispatch();
  const [PopupImage, setPopupImage] = useState(false);
  const [PopupImageData, setPopupImageData] = useState([]);
  const FormulaCalcColLst = useSelector((s) => s.formulaCalcColLst[0][tbl_name]);
  const ActionItemData = useSelector((s) => s.ActionItemData);
  const LinelistData = useSelector((s) => s.LinelistData);
  const SSNData = useSelector((s) => s.LinelistDataSSN);
  let unsentCount = useSelector((s) => s.LineListUnsentCount || 0);
  const tmpColDef = useSelector((s) => s.LinelistColDef || []);
  const LineListHighlistCell = useSelector((s) => s.LineListHighlistCell);
  const columnGrpPref = useSelector((s) => s.LinelistGrpColPref);
  const columnPref = useSelector((s) => s.LinelistColPref);

  const [LinelistColDef, setLinelistColDef] = useState([]);
  const varSorting = useSelector((s) => s.LinelistSorting || []);
  const varFilters = useSelector((s) => s.LinelistFilters || {});
  const refDeliveryMaster = useSelector((s) => s.DeliveryMasterData);
  const refDeliveryMasterChange = useSelector((s) => s.DeliveryMasterHighlistCell);
  const refFBFtyDatabase = useSelector((s) => s.FbFtyDatabaseData);
  const refFBFtyDatabaseChange = useSelector((s) => s.FbFtyDatabaseHighlistCell);
  const refFreightMaster = useSelector((s) => s.FreightMasterData);
  const refFreightMasterChange = useSelector((s) => s.FreightMasterHighlistCell);
  const refFreightByCategory = useSelector((s) => s.FreightByCategoryData);
  const refFreightByCategoryChange = useSelector((s) => s.FreightByCategoryHighlistCell);
  const [isGridReady, setIsGridReady] = useState(false);
  const changedData = useRef([])
  const selectedChngData = useRef([])
  const highlightCells = useRef([]);
  const gridApi = useRef({});
  const gridColumnApi = useRef({});
  const cellValueChanged = useRef([]);
  const [autoChangeState, setAutoChangeState] = useState(false)
  const [autoGetChangeResult, setAutoGetChangeResult] = useState({});
  const [bottomRowData, setBottomRowData] = useState([]);
  const [pivotState, setPivotState] = useState(false);
  const llTabPvtData = useSelector(s => s.llTabPvtData);

  const gridRef = useRef();

  const footerRowCalCols = ["Costing Status", "Current FOB Status", "Current LDP - Status", "Delivery Status", "MSRP", "WHLS", "Style Activity", "Total DTC SLS $", "Total WHSL SLS $", "Total Brand SLS $", "Previous FOB", "Placeholder/Flash FOB", "300 - FOB", "500 - FOB", "1000 - FOB", "Faherty Target FOB", "Final FOB", "PO Override", "Current FOB", "Duty Rate", "Air Freight", "Sea Freight", "Air %", "Blended Freight", "300 - LDP", "500 - LDP", "1000 - LDP", "LDP", "DTC Projections", "WHSL Projections", "Total Projections", "Stores", "Ecomm", "DTC Units Total", "International Wholesale Units", "Wholesale Units Total", "Brand Units Total", "DTC Chase Units", "Wholesale Chase Units", "DTC Units Total + DTC Chase Units", "Wholesale Units Total + Wholesale Chase Units", "DTC SMS Units", "Wholesale SMS Units", "SKU Count"]
  const setResetFilter = (set) => {
    gridApi.current.applyColumnState({ state: set === "reset" ? null : varSorting });
    gridApi.current.setFilterModel(set === "reset" ? null : varFilters);
  };
  const onSortChanged = () => {
    const colState = gridApi.current.getColumnState();
    const savedSortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });
    dispatch({ type: "SET_LINELIST_SORTING", data: savedSortState });
  };
  const onFilterChanged = () => {
    const savedFilterModel = gridApi.current.getFilterModel();
    dispatch({ type: "SET_LINELIST_FILTERS", data: savedFilterModel });
    calcBottomRow();
  };
  useImperativeHandle(ref, () => ({
    validateSendData: () => {
      if (!LinelistData) return false
      const res = executeDataVerification(null, tbl_name, highlightCells.current, gridApi.current, gridColumnApi.current);
      if (res.status === false) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        gridApi.current.refreshCells({ force: true })
        return (res)
      }
      removeHeihlight();
      const user_type = sessionStorage.getItem("user_type").toLowerCase();
      if (["admin", "product development"].includes(user_type)) {
        checkProductionLockValidations();
      }
      if (user_type !== "merch reference" && user_type !== "vendor") {
        populateLinelistSKULock();
      }
      if (["admin", "product development"].includes(user_type)) {
        performProductionLock();
        performAllowChanges();
      }
      cellValueChanged.current = [];
      return ({ status: true })
    },
    getChangesFunc: async (masterData, metaData) => {
      const res = await compareAndHighLightDataChanges(masterData, rng_name, tbl_name, tmpColDef, gridApi.current, cellValueChanged.current, highlightCells.current, metaData)
      if (res.status) {
        highlightCells.current = res.highlightCells;
        setHighlightByTblName(tbl_name, highlightCells.current);
        dispatch({ type: "SET_LINELIST_DATA", data: res.data });

      } else {
        toastMessage(res.msg, "warning");
      }
      return res;
    },
    getAutoChanges: (data, downloadType) => {
      changedData.current = []
      setResetFilter("reset")
      const res = GetAutoChanges(data, highlightCells.current, gridApi.current, cellValueChanged.current, tbl_name, downloadType)
      setAutoChangeState(true);
      setAutoGetChangeResult(res);
      setResetFilter("set")
    }
  }));
  const removeHeihlight = () => {
    highlightCells.current = [];
    if (Object.keys(gridApi.current).length > 0) gridApi.current.refreshCells({ force: true })
  };
  const calculateSKU = (event) => {
    let Style = event.data["Style"] || "";
    let ColorCode = event.data["Color Code"] || "";
    return Style + "-" + ColorCode;
  };
  const calculateWorkingCost = (event) => {
    let resultVal = 0;
    let BulkCost = parseFloat(event.data["Bulk Cost"] || 0);
    let InitialCostOverride = parseFloat(event.data["Initial Cost Override"] || 0);
    let InitialCost = parseFloat(event.data["Initial Cost"] || 0);
    let PlaceholderFlashFOB = parseFloat(event.data["Placeholder/Flash FOB"] || 0);
    let PreviousFOB = parseFloat(event.data["Previous FOB"] || 0);
    if (BulkCost === 0) {
      if (InitialCostOverride === 0) {
        if (InitialCost === 0) {
          if (PlaceholderFlashFOB === 0) resultVal = PreviousFOB;
          else resultVal = PlaceholderFlashFOB;
        }
        else resultVal = InitialCost;
      }
      else resultVal = InitialCostOverride;
    }
    else resultVal = BulkCost;
    return resultVal;
  };
  const calculateCurrentFOB = (event) => {
    let resultVal = "";
    let FinalFOB = parseFloat(event.data["Final FOB"] || 0);
    let WorkingCost = parseFloat(event.data["Working Cost"] || 0);
    if (FinalFOB > 0) resultVal = FinalFOB;
    else if (WorkingCost > 0) resultVal = WorkingCost;
    return resultVal;
  };
  const calculateCurrentFOBStatus = (event) => {
    let resultVal = "Working";
    let CostingStatus = event.data["Costing Status"] || "";
    if (CostingStatus === "Confirmed FOB") resultVal = "Confirmed";
    return resultVal;
  };
  const calculateMerchFee = (event) => {
    let resultVal = 0.003464;
    let DutyRate = parseFloat(event.data["Duty Rate"] || 0);
    if (DutyRate === 0) resultVal = 0;
    return resultVal;
  };
  const calculateHarborMaintenanceFee = (event) => {
    let resultVal = 0.000625;
    let DutyRate = parseFloat(event.data["Duty Rate"] || 0);
    if (DutyRate === 0) resultVal = 0;
    return resultVal;
  };
  const calculateCOO = (event) => {
    let resultVal = "";
    let Factory = event.data["Factory"] || "";
    let Factory_Ovrd = event.data["Factory Override"] || "";
    if (Factory_Ovrd !== "")
      resultVal = xlXlookup(Factory_Ovrd, refFBFtyDatabase, 'Factory', 'Factory Country')
    else
      if (Factory !== "")
        resultVal = xlXlookup(Factory, refFBFtyDatabase, 'Factory', 'Factory Country')
    return resultVal;
  };
  const calculateSection301 = (event) => {
    let resultVal = 0;
    let ProductionCOO = (event.data["Production COO"] || "").trim().toUpperCase();
    let COO = (event.data["COO"] || "").trim().toUpperCase();
    if (ProductionCOO === "") {
      if (['CHINA', 'HONG KONG'].includes(COO)) resultVal = 0.075;
    } else {
      if (['CHINA', 'HONG KONG'].includes(ProductionCOO)) resultVal = 0.075;
    }
    return resultVal;
  };
  const calculateSKUCount = (event) => {
    return (event.data["Style Activity"] || "").trim().toUpperCase() === "ACTIVE" ? 1 : 0;
  };
  const calculateTotalProjections = (event) => {
    return xlMround(parseFloat(event.data["DTC Projections"] || 0) + parseFloat(event.data["WHSL Projections"] || 0), 1);
  };
  const calculateDTCUnitsTotal = (event) => {
    return xlMround(parseFloat(event.data["Stores"] || 0) + parseFloat(event.data["Ecomm"] || 0), 1);
  };
  const calculateBrandUnitsTotal = (event) => {
    return xlMround(parseFloat(event.data["Wholesale Units Total"] || 0) + parseFloat(event.data["DTC Units Total"] || 0), 1);
  };
  const calculateBrandChaseUnits = (event) => {
    return parseInt(event.data["DTC Chase Units"] || 0) + parseInt(event.data["Wholesale Chase Units"] || 0);
  };
  const calculateTotalDTCSLS = (event) => {
    return xlRound(parseFloat(event.data["MSRP"] || 0) * parseFloat(event.data["DTC Units Total"] || 0), 6);
  };
  const calculateTotalWHSLSLS = (event) => {
    return xlRound(parseFloat(event.data["WHLS"] || 0) * parseFloat(event.data["Wholesale Units Total"] || 0), 6);
  };
  const calculateTotalWBrandSLS = (event) => {
    return parseFloat(event.data["Total DTC SLS $"] || 0) + parseFloat(event.data["Total WHSL SLS $"] || 0);
  };
  const calculateDutyTotal = (event) => {
    const DutyRateOverride = event.data["Duty Rate Override"] || "";
    let retVal = "";
    if (DutyRateOverride === "")
      retVal = xlRound(parseFloat(event.data["Duty Rate"] || 0) + parseFloat(event.data["Merch Fee"] || 0) + parseFloat(event.data["Harbor Maintenance Fee"] || 0) + parseFloat(event.data["Section 301"] || 0), 6);
    else
      retVal = xlRound(parseFloat(event.data["Duty Rate Override"] || 0) + parseFloat(event.data["Merch Fee"] || 0) + parseFloat(event.data["Harbor Maintenance Fee"] || 0) + parseFloat(event.data["Section 301"] || 0), 6);
    return retVal;
  };
  const calculateAirFreight = (event) => {
    let SeasonCode = (event.data["Season Code"] || "").toLowerCase();
    let COO = (event.data["COO"] || "").toLowerCase();
    let Category = (event.data["Category"] || "").toLowerCase();
    let AIR = 0;
    let FinalKG = 0
    refFreightMaster.forEach((element) => {
      if (element["Season"].toLowerCase() === SeasonCode && (element["COO"] || "").toLowerCase() === COO)
        AIR += parseFloat(element["AIR"] || 0);
    });
    refFreightByCategory.forEach((element) => {
      if (element["Season"].toLowerCase() === SeasonCode && (element["Category"] || "").toLowerCase() === Category)
        FinalKG += parseFloat(element["Final KG"] || 0);
    });
    return xlRound(AIR * FinalKG, 6);
  };
  const calculateSeaFreight = (event) => {
    let SeasonCode = (event.data["Season Code"] || "").toLowerCase();
    let COO = (event.data["COO"] || "").toLowerCase();
    let Category = (event.data["Category"] || "").toLowerCase();
    let BOAT = 0;
    let FinalKG = 0
    refFreightMaster.forEach((element) => {
      if (element["Season"].toLowerCase() === SeasonCode && (element["COO"] || "").toLowerCase() === COO)
        BOAT += parseFloat(element["BOAT"] || 0);
    });
    refFreightByCategory.forEach((element) => {
      if (element["Season"].toLowerCase() === SeasonCode && (element["Category"] || "").toLowerCase() === Category)
        FinalKG += parseFloat(element["Final KG"] || 0);
    });
    return xlRound(BOAT * FinalKG, 6);
  };
  const calculateBlendedFreight = (event) => {
    let AirFreight = parseFloat(event.data["Air Freight"] || 0);
    let SeaFreight = parseFloat(event.data["Sea Freight"] || 0);
    let AirSea = ((AirFreight * 0.35) + (SeaFreight * 0.65)).toFixed(10);
    if (parseFloat(AirSea) === 0) AirSea = 2;
    return xlRound(AirSea, 6);
  };
  const calculateScenarioLDP = (event) => {
    let ScenarioCost = parseFloat(event.data["Scenario Cost"] || 0);
    let BlendedFreight = parseFloat(event.data["Blended Freight"] || 0);
    let DutyRate = parseFloat(event.data["Duty Rate"] || 0);
    let MerchFee = parseFloat(event.data["Merch Fee"] || 0);
    let HarborMaintenanceFee = parseFloat(event.data["Harbor Maintenance Fee"] || 0);
    let Section301 = parseFloat(event.data["Section 301"] || 0);
    let resultVal = (ScenarioCost + BlendedFreight + (ScenarioCost * DutyRate) + (ScenarioCost * MerchFee) + (ScenarioCost * HarborMaintenanceFee) + (ScenarioCost * Section301)).toFixed(10);
    return xlRound(resultVal, 6);
  };
  const calculateAgentCommissionRate = (event) => {
    let Factory = event.data["Factory"] || "";
    let Factory_Ovrd = event.data["Factory Override"] || "";
    let resultVal = 0;
    if (Factory_Ovrd !== "")
      resultVal = xlXlookup(Factory_Ovrd, refFBFtyDatabase, 'Factory', 'Commission Percent');
    else
      if (Factory !== "")
        resultVal = xlXlookup(Factory, refFBFtyDatabase, 'Factory', 'Commission Percent');
    return resultVal === "" ? 0 : resultVal;
  };
  const calculate300LDP = (event) => {
    let FOB300 = parseFloat(event.data["300 - FOB"] || 0);
    let BlendedFreight = parseFloat(event.data["Blended Freight"] || 0);
    let DutyRate = parseFloat(event.data["Duty Rate"] || 0);
    let AgentCommissionRate = parseFloat(event.data["Agent Commission Rate"] || 0);
    let resultVal = (FOB300 + BlendedFreight + (DutyRate * FOB300) + (AgentCommissionRate * FOB300)).toFixed(10);
    return xlRound(resultVal, 6);
  };
  const calculate500LDP = (event) => {
    let FOB500 = parseFloat(event.data["500 - FOB"] || 0);
    let BlendedFreight = parseFloat(event.data["Blended Freight"] || 0);
    let DutyRate = parseFloat(event.data["Duty Rate"] || 0);
    let AgentCommissionRate = parseFloat(event.data["Agent Commission Rate"] || 0);
    let resultVal = (FOB500 + BlendedFreight + (DutyRate * FOB500) + (AgentCommissionRate * FOB500)).toFixed(10);
    return xlRound(resultVal, 6);
  };
  const calculate1000LDP = (event) => {
    let FOB1000 = parseFloat(event.data["1000 - FOB"] || 0);
    let BlendedFreight = parseFloat(event.data["Blended Freight"] || 0);
    let DutyRate = parseFloat(event.data["Duty Rate"] || 0);
    let AgentCommissionRate = parseFloat(event.data["Agent Commission Rate"] || 0);
    let resultVal = (FOB1000 + BlendedFreight + (DutyRate * FOB1000) + (AgentCommissionRate * FOB1000)).toFixed(10);
    return xlRound(resultVal, 6);
  };
  const calculateLDP = (event) => {
    let CurrentFOB = parseFloat(event.data["Current FOB"] || 0);
    let BlendedFreight = parseFloat(event.data["Blended Freight"] || 0);
    let DutyTotal = parseFloat(event.data["Duty Total"] || 0);
    let AgentCommissionRate = parseFloat(event.data["Agent Commission Rate"] || 0);
    let resultVal = (CurrentFOB + BlendedFreight + (CurrentFOB * DutyTotal) + (CurrentFOB * AgentCommissionRate)).toFixed(10);
    return xlRound(resultVal, 6);
  };
  const calculate300IMU = (event) => {
    let MSRP = parseFloat(event.data["MSRP"] || 0);
    let LDP300 = parseFloat(event.data["300 - LDP"] || 0);
    let resultVal = "";
    if (MSRP !== 0) resultVal = xlRound(((MSRP - LDP300) / MSRP).toFixed(10), 6);
    return resultVal;
  };
  const calculate500IMU = (event) => {
    let MSRP = parseFloat(event.data["MSRP"] || 0);
    let LDP500 = parseFloat(event.data["500 - LDP"] || 0);
    let resultVal = "";
    if (MSRP !== 0) resultVal = xlRound(((MSRP - LDP500) / MSRP).toFixed(10), 6);
    return resultVal;
  };
  const calculate1000IMU = (event) => {
    let MSRP = parseFloat(event.data["MSRP"] || 0);
    let LDP1000 = parseFloat(event.data["1000 - LDP"] || 0);
    let resultVal = "";
    if (MSRP !== 0) resultVal = xlRound(((MSRP - LDP1000) / MSRP).toFixed(10), 6);
    return resultVal;
  };
  const calculateRTLIMU = (event) => {
    let MSRP = parseFloat(event.data["MSRP"] || 0);
    let LDP = parseFloat(event.data["LDP"] || 0);
    let resultVal = "";
    if (MSRP !== 0) resultVal = xlRound(((MSRP - LDP) / MSRP).toFixed(10), 6);
    return resultVal;
  };
  const calculateWHLSIMU = (event) => {
    let WHLS = parseFloat(event.data["WHLS"] || 0);
    let LDP = parseFloat(event.data["LDP"] || 0);
    let resultVal = "";
    if (WHLS !== 0) resultVal = xlRound(((WHLS - LDP) / WHLS).toFixed(10), 6);
    return resultVal;
  };
  const calculateDTCUnitsTTLChsUnits = (event) => {
    return parseInt(event.data["DTC Units Total"] || 0) + parseInt(event.data["DTC Chase Units"] || 0);
  };
  const calculateWHSLUnitsTTLChsUnits = (event) => {
    return parseInt(event.data["Wholesale Units Total"] || 0) + parseInt(event.data["Wholesale Chase Units"] || 0);
  };
  const calculateBrndTTLIncldChs = (event) => {
    return parseInt(event.data["Brand Units Total"] || 0) + parseInt(event.data["Brand Chase Units"] || 0);
  };
  const calculateBrand = (event) => {
    let Department = (event.data["Department"] || "").trim().toUpperCase();
    return (['MENS', 'WOMENS', 'KIDS'].includes(Department)) ? "Faherty Brand" : "";
  };
  const calculateDeliveryDateInDC = (event) => {
    let SeasonCode = event.data["Season Code"] || "";
    let DeliveryMonth = event.data["Delivery Month"] || "";
    let resultVal = "";
    if (SeasonCode !== "" && DeliveryMonth !== "")
      resultVal = xlXlookup(SeasonCode + DeliveryMonth, refDeliveryMaster, 'Season Code|Month', 'Delivery Date/ In DC');
    return resultVal;
  };
  const calculateTransitDays = (event) => {
    let SeasonCode = event.data["Season Code"] || "";
    let COO = event.data["COO"] || "";
    let resultVal = 0;
    if (SeasonCode !== "" && COO !== "")
      resultVal = xlXlookup(SeasonCode + COO, refFreightMaster, 'Season|COO', 'Ocean Transit Days');
    return resultVal;
  };
  const calculateTargetXftyDate = (event) => { // need to verify
    let DeliveryDateInDC = event.data["Delivery Date/In DC"] || 0;
    let TransitDays = parseInt(event.data["Transit Days"] || 0);
    let resultVal = "";
    if (DeliveryDateInDC !== 0) {
      if (DeliveryDateInDC.toString().includes("/"))
        DeliveryDateInDC = JsDateToExcelDateNumber(DeliveryDateInDC);
      resultVal = (parseInt(DeliveryDateInDC) - TransitDays);
    }
    return resultVal;
  };
  const calculateInDCDateOcean = (event) => { // need to verify
    let XftyDate = event.data["X-fty Date"] || 0;
    let TransitDays = parseInt(event.data["Transit Days"] || 0);
    let resultVal = "";
    if (XftyDate !== 0) {
      if (XftyDate.toString().includes("/"))
        XftyDate = JsDateToExcelDateNumber(XftyDate);
      resultVal = (parseInt(XftyDate) + TransitDays);
    }
    return resultVal;
  };
  const calculateDaysLate = (event) => { // need to verify
    let DeliveryDateInDC = event.data["Delivery Date/In DC"] || 0;
    let InDCDateOcean = event.data["In DC Date (Ocean)"] || 0;
    let resultVal = "";
    if (DeliveryDateInDC !== 0 && InDCDateOcean !== 0) {
      if (DeliveryDateInDC.toString().includes("/")) DeliveryDateInDC = JsDateToExcelDateNumber(DeliveryDateInDC);
      if (InDCDateOcean.toString().includes("/")) InDCDateOcean = JsDateToExcelDateNumber(InDCDateOcean);
      resultVal = (parseInt(DeliveryDateInDC) - parseInt(InDCDateOcean));
    }
    return resultVal;
  };
  const calculateHalfYear = (event) => {
    let resultVal = "";
    let SeasonCode = event.data["Season Code"] || "";
    let DeliveryMonth = event.data["Delivery Month"] || "";
    if (SeasonCode !== "" && DeliveryMonth !== "")
      resultVal = xlXlookup(SeasonCode + DeliveryMonth, refDeliveryMaster, 'Season Code|Month', 'Half Year');
    return resultVal;
  };
  const calculateCorporateAgent = (event) => {
    let resultVal = "";
    let Factory = event.data["Factory"] || "";
    let Factory_Ovrd = event.data["Factory Override"] || "";
    if (Factory_Ovrd !== "")
      resultVal = xlXlookup(Factory_Ovrd, refFBFtyDatabase, 'Factory', 'Corporate/Agent');
    else
      if (Factory !== "")
        resultVal = xlXlookup(Factory, refFBFtyDatabase, 'Factory', 'Corporate/Agent');
    return resultVal;
  };
  const calculateShippingTermsFOBDDPLDP = (event) => {
    let resultVal = "";
    let Factory = event.data["Factory"] || "";
    let Factory_Ovrd = event.data["Factory Override"] || "";
    if (Factory_Ovrd !== "")
      resultVal = xlXlookup(Factory_Ovrd, refFBFtyDatabase, 'Factory', 'FOB/DDP/LDP')
    else
      if (Factory !== "")
        resultVal = xlXlookup(Factory, refFBFtyDatabase, 'Factory', 'FOB/DDP/LDP')
    return resultVal;
  };
  const calculateCFSCutOffDayofweek = (event) => {
    let resultVal = "";
    let SeasonCode = event.data["Season Code"] || "";
    let COO = event.data["COO"] || "";
    if (SeasonCode !== "" && COO !== "")
      resultVal = xlXlookup(SeasonCode + COO, refFreightMaster, 'Season|COO', 'CFS Cut Off (Day of Week)');
    return resultVal;
  };
  const calculateDeliveryPeriod = (event) => {
    let resultVal = "";
    let SeasonCode = event.data["Season Code"] || "";
    let DeliveryMonth = event.data["Delivery Month"] || "";
    if (SeasonCode !== "" && DeliveryMonth !== "")
      resultVal = xlXlookup(SeasonCode + DeliveryMonth, refDeliveryMaster, 'Season Code|Month', 'Delivery Period');
    return resultVal;
  };
  const calculateInStore = (event) => {
    let resultVal = "";
    let SeasonCode = event.data["Season Code"] || "";
    let DeliveryMonth = event.data["Delivery Month"] || "";
    if (SeasonCode !== "" && DeliveryMonth !== "")
      resultVal = xlXlookup(SeasonCode + DeliveryMonth, refDeliveryMaster, 'Season Code|Month', 'In Store');
    return resultVal;
  };
  const calculateMinimumMet = (event) => {
    let resultVal = "NO";
    let BrandUnitsTotal = parseFloat(event.data["Brand Units Total"] || 0);
    let MinimumBuy = event.data["Minimum Buy"] || "";
    if (MinimumBuy === "") resultVal = "";
    else if (BrandUnitsTotal > parseFloat(MinimumBuy || 0)) resultVal = "YES";
    return resultVal;
  };
  const calculateCostUnderMin = (event) => {
    let BulkCost = parseFloat(event.data["Bulk Cost"] || 0);
    let UpchargeforunderMinimum = parseFloat(event.data["Upcharge for under Minimum"] || 0);
    return xlRound(BulkCost * (1 + UpchargeforunderMinimum), 6);
  };
  const calculateCostUnderMinEstimated = (event) => {
    let resultVal = "";
    let CurrentFOB = parseFloat(event.data["Current FOB"] || 0);
    let UpchargeforunderMinimum = parseFloat(event.data["Upcharge for under Minimum"] || 0);
    if (CurrentFOB !== 0) {
      resultVal = xlRound(CurrentFOB * (1 + UpchargeforunderMinimum), 6);
    }
    return resultVal;
  };
  const calculateScenarioRTLIMU = (event) => {
    let resultVal = "";
    let MSRP = parseFloat(event.data["MSRP"] || 0);
    let ScenarioLDP = parseFloat(event.data["Scenario LDP"] || 0);
    if (MSRP !== 0)
      resultVal = xlRound(((MSRP - ScenarioLDP) / MSRP).toFixed(10), 6);
    return resultVal;
  };
  const calculateScenarioWHLSIMU = (event) => {
    let resultVal = "";
    let WHLS = parseFloat(event.data["WHLS"] || 0);
    let ScenarioLDP = parseFloat(event.data["Scenario LDP"] || 0);
    if (WHLS !== 0)
      resultVal = xlRound(((WHLS - ScenarioLDP) / WHLS).toFixed(10), 6);
    return resultVal;
  };
  const calculateWorkingVsScenarioRTLIMU = (event) => {
    let resultVal = "";
    let RTLIMU = parseFloat(event.data["RTL IMU%"] || 0);
    let ScenarioRTLIMU = parseFloat(event.data["Scenario RTL IMU%"] || 0);
    if (RTLIMU !== "")
      resultVal = xlRound((RTLIMU - ScenarioRTLIMU).toFixed(10), 6);
    return resultVal;
  };
  const calculateWorkingVsScenarioWHSLIMU = (event) => {
    let resultVal = "";
    let WHLSIMU = parseFloat(event.data["WHLS IMU%"] || 0);
    let ScenarioWHLSIMU = parseFloat(event.data["Scenario WHLS IMU%"] || 0);
    if (WHLSIMU !== "")
      resultVal = xlRound((WHLSIMU - ScenarioWHLSIMU).toFixed(10), 6);
    return resultVal;
  };
  const calculateDTCBOPDTCTotalUnits = (event) => {
    let DTCBOP = parseFloat(event.data["DTC BOP"] || 0);
    let DTCUnitsTotal = parseFloat(event.data["DTC Units Total"] || 0);
    return xlRound(DTCBOP + DTCUnitsTotal, 6);
  };
  const calculateMerchColumn = (event, column) => {
    return event.data[column] || "";
  };
  const calculateCBMPerCarton = (event) => {
    return xlRound((parseFloat(event.data["Length (CM)"] || 0) * parseFloat(event.data["Width (CM)"] || 0) * parseFloat(event.data["Height (CM)"] || 0)) / 1000000, 6);
  };
  const calculateCBMPerUnit = (event) => {
    const QuantityPerCarton = parseFloat(event.data["Quantity Per Carton"] || 0);
    const cbmPerCarton = parseFloat(event.data["CBM Per Carton"] || 0);
    const result = QuantityPerCarton !== 0 ? cbmPerCarton / QuantityPerCarton : 0;

    return QuantityPerCarton === 0 ? "" : xlRound(result, 6);
  };
  const calculateKGPerUnit = (event) => {
    const CBMPerUnit = event.data["CBM Per Unit"] || "";
    return CBMPerUnit === "" ? "" : xlRound(parseFloat(event.data["CBM Per Unit"] || 0) * 167, 6);
  };
  const funCellStyle = (params, col) => {
    if (isGridReady && gridColumnApi?.current?.isPivotMode()) return;
    if (!params?.node?.data && !params?.node?.data?.row_id) return;
    const higlightStyleColumns = ["Style", "Style Description", "Color Code", "Color Description"]
    const value = params.value ? params.value : "";
    let bgColor = "";
    let bdrColor = "";
    let txtDeco = "";
    let fontColor = "#000000";
    let boldTxt = "normal";
    let borderBottom = "";
    let fontStyle = "normal";
    const customCode = getColumnStyle(tmpColDef, value, params, col);
    if (customCode.status) {
      bgColor = customCode.bgColor;
      bdrColor = customCode.bdrColor;
      txtDeco = customCode.txtDeco;
      fontColor = customCode.fontColor;
      boldTxt = customCode.boldTxt;
      borderBottom = customCode.borderBottom;
      fontStyle = customCode.fontStyle;
    } else {
      const editable = isEditable(params, col.field, col.editable, col.insert_editable);
      if (editable) bgColor = "#FFFFFF";
      else bgColor = "#F0F0F0";
      if (editable && col.type === "dropdown") bgColor = "#FDFCEC";
    }
    //change font color if highlight style performed
    if (sessionStorage.getItem("user_type") === "merch") {
      if (higlightStyleColumns.includes(col.headerName) && params.node.data["Merch Highlight"] && params.node.data["Merch Highlight"].toLowerCase() === "true")
        fontColor = "red";
      else
        fontColor = "black"
    }
    let key = tbl_name + "|" + col.headerName.trim() + "|" + params.node.data.row_id
    let obj = checkHeihlight(key, highlightCells.current)
    if (obj.status === true) {
      if (obj.colorCode == 1) bgColor = "cyan";
      else if (obj.colorCode == 2) bgColor = "#d5a7f2";
      else if (obj.colorCode == 3) bdrColor = "#2196f3";
      else if (obj.colorCode === 4) bdrColor = "#9B6155";
    }
    if (params.node.rowPinned === "bottom" && params.node.rowIndex === 0) { bgColor = "#C7F9CC"; boldTxt = "bold"; txtDeco = "" }
    if (params.node.rowPinned === "bottom" && params.node.rowIndex === 1) { bgColor = "#FBF8CC"; boldTxt = "bold"; txtDeco = "" }
    // ActionItem Footer Highlight
    if (checkActionItemAssigned(ActionItemData, params, tbl_name)) {
      borderBottom = "purple"; boldTxt = "bold";
    }

    if (bdrColor !== "")
      return { backgroundColor: bgColor, fontWeight: boldTxt, textDecoration: txtDeco, color: fontColor, borderColor: bdrColor, fontStyle: fontStyle }
    else
      return { backgroundColor: bgColor, fontWeight: boldTxt, textDecoration: txtDeco, color: fontColor, borderBottomColor: borderBottom, fontStyle: fontStyle }
  };
  const imageCellRander = (params) => {
    let src = "https://mediasvr.sohum.tech/ciimages/cismallimages/" + params.data.image
    return <img
      onClick={() => {
        setPopupImageData(params.data);
        setPopupImage(true);
      }}
      style={{ height: "30px" }}
      src={src}
    />;
  }

  useEffect(() => {
    if (tmpColDef && tmpColDef.length > 0 && LinelistColDef.length === 0) {
      const historicDataCol = ["MSRP", "WHLS", "Previous FOB", "Placeholder/Flash FOB", "Initial Cost", "300 - FOB", "500 - FOB", "1000 - FOB", "Bulk Cost", "Minimum Buy", "Upcharge for under Minimum",
        "Faherty Target FOB", "Factory Counter", "Final FOB", "Scenario Cost", "PO Override", "Duty Rate"];

      let temp = tmpColDef;
      let param_editable = false;

      temp = temp.map((col) => {
        let mpCol = col
        if (col.field === "image") {
          mpCol = {
            cellRenderer: imageCellRander,
            headerName: "",
            field: col.field,
            colId: col.colId,
            width: 30,
            editable: (params) => isEditable(params, col.field, col.editable, col.insert_editable),
            suppressColumnsToolPanel: col.hide,
            suppressFiltersToolPanel: col.hide,
            myType: col.type,
            suppressKeyboardEvent: col.suppressKeyboardEvent,
            // filter: col.filter,
            // filterParams: col.filterParams,            
            autoHeaderHeight: true,
            resizable: col.resizable,
            wrapText: col.wrapText,
            valueFormatter: col.valueFormatter,
            valueParser: col.valueParser,
          }
        } else if (col.field === "Days Late") {
          mpCol = {
            suppressSpanHeaderHeight: true,
            headerName: col.headerName,
            field: col.field,
            colId: col.colId,
            hide: col.hide,
            suppressColumnsToolPanel: col.hide,
            suppressFiltersToolPanel: col.hide,
            myType: col.type,
            suppressKeyboardEvent: col.suppressKeyboardEvent,
            filter: col.filter,
            filterParams: col.filterParams,
            width: col.width,
            autoHeaderHeight: true,
            resizable: col.resizable,
            wrapText: col.wrapText,
            autoHeaderHeight: true,
            editable: params => isEditable(params, col.field, col.editable, col.insert_editable),
            cellClass: col.type === "dropdown" ? "" : col.type,
            cellStyle: params => { return funCellStyle(params, col) },
            cellRenderer: (params) => {
              if (params.node.rowPinned === "bottom") return params.value;
              let max = 1100 // res.maxDD
              let min = -1000 // res.minDD
              const data = params.value
              let color = "#F0F0F0" //C8D8E4
              if (data !== null && data !== undefined && data !== '') {
                color = "#c6efce"
                if (data < 0) color = "#ffc2cf"
              }
              return (
                <Charts
                  value={params.value}
                  maxValue={max}
                  minValue={min}
                  color={color}
                />
              );
            },
          }
        } else {
          param_editable = false;
          mpCol = {
            suppressSpanHeaderHeight: true,
            headerName: col.headerName,
            field: col.field,
            colId: col.colId,
            hide: col.hide,
            suppressColumnsToolPanel: col.hide,
            suppressFiltersToolPanel: col.hide,
            cellEditor: col.type === "dropdown" || col.type === "date" ? col.cellEditor : "",
            cellEditorParams: col.type === "dropdown" || col.type === "date" ? col.cellEditorParams : "",
            myType: col.type,
            suppressKeyboardEvent: col.suppressKeyboardEvent,
            filter: col.filter,
            filterParams: col.filterParams,
            width: col.width,
            autoHeaderHeight: true,
            resizable: col.resizable,
            wrapText: col.wrapText,
            cellClass: col.type === "dropdown" ? "" : col.type,
            autoHeaderHeight: true,
            editable: params => {
              const costingStatus = params.node.data["Costing Status"];
              if (sessionStorage.getItem('Template_Type') === "vendor") {
                if (costingStatus === "Confirmed X-Fty" || costingStatus === "Confirmed FOB and X-Fty")
                  param_editable = false
              }
              param_editable = isEditable(params, col.field, col.editable, col.insert_editable);
              return param_editable;
            },
            cellStyle: params => { return funCellStyle(params, col) },
            valueFormatter: col.valueFormatter,
            valueParser: col.valueParser,
          }
          // if (historicDataCol.includes(col.headerName) && col.editable) {
          //   mpCol["tooltipField"] = col.field;
          //   mpCol["tooltipValueGetter"] = `List shows historic data of ${col.headerName}`;

          //   mpCol["cellEditor"] = CustomAutocompleteEditor;
          //   mpCol["cellEditorParams"] = {
          //     "table_name":tbl_name
          //   }
          // }
        }
        return mpCol;
      })
      setLinelistColDef(generateColumnGrouping(colGrpLinelist, temp));
    }
    calcBottomRow();
    changedData.current = []
    selectedChngData.current = []
    cellValueChanged.current = []
  }, [LinelistData, ActionItemData]);
  useEffect(() => {
    if (tmpColDef && LinelistColDef.length !== 0) {
      let temp = tmpColDef;
      temp = temp.map((col) => {
        let mpCol = col
        if (col.field === "Days Late") {
          mpCol = {
            suppressSpanHeaderHeight: true,
            headerName: col.headerName,
            field: col.field,
            colId: col.colId,
            hide: col.hide,
            suppressColumnsToolPanel: col.hide,
            suppressFiltersToolPanel: col.hide,
            myType: col.type,
            suppressKeyboardEvent: col.suppressKeyboardEvent,
            filter: col.filter,
            filterParams: col.filterParams,
            width: col.width,
            autoHeaderHeight: true,
            resizable: col.resizable,
            wrapText: col.wrapText,
            autoHeaderHeight: true,
            editable: params => isEditable(params, col.field, col.editable, col.insert_editable),
            cellStyle: params => { return funCellStyle(params, col) },
            cellRenderer: (params) => {
              if (params.node.rowPinned === "bottom") return params.value;
              let max = 1100 // res.maxDD
              let min = -1000 // res.minDD
              const data = params.value
              let color = "#F0F0F0" //C8D8E4
              if (data !== null && data !== undefined && data !== '') {
                color = "#c6efce"
                if (data < 0) color = "#ffc2cf"
              }
              return (
                <Charts
                  value={params.value}
                  maxValue={max}
                  minValue={min}
                  color={color}
                />
              );
            },
          }
        } else {
          mpCol = {
            suppressSpanHeaderHeight: true,
            headerName: col.headerName,
            field: col.field,
            colId: col.colId,
            hide: col.hide,
            suppressColumnsToolPanel: col.hide,
            suppressFiltersToolPanel: col.hide,
            cellEditor: col.type === "dropdown" || col.type === "date" ? col.cellEditor : "",
            cellEditorParams: col.type === "dropdown" || col.type === "date" ? col.cellEditorParams : "",
            myType: col.type,
            suppressKeyboardEvent: col.suppressKeyboardEvent,
            filter: col.filter,
            filterParams: col.filterParams,
            width: col.width,
            autoHeaderHeight: true,
            resizable: col.resizable,
            wrapText: col.wrapText,
            autoHeaderHeight: true,
            editable: params => {
              const costingStatus = params.node.data["Costing Status"];
              if (sessionStorage.getItem('Template_Type') === "vendor") {
                if (costingStatus === "Confirmed X-Fty" || costingStatus === "Confirmed FOB and X-Fty")
                  return false
              }
              return isEditable(params, col.field, col.editable, col.insert_editable)
            },
            cellStyle: params => { return funCellStyle(params, col) },
            valueFormatter: col.valueFormatter,
            valueParser: col.valueParser,
          }
        }

        if (col.type && (col.type === "qty" || col.type === "price" || col.type === "percentage" || col.type.includes("decimal"))) {
          mpCol.valueGetter = (params) => {
            if (params.data) {
              let value = params.data[col.headerName];
              if (value !== null && value !== undefined && value !== "") value = Number(value)
              return value;
            }
            mpCol.cellDataType = 'number';
            mpCol.aggFunc = 'sum';

            return "";
          }
          mpCol.cellDataType = 'number';
          mpCol.aggFunc = 'sum';
        }
        else if (col.type && col.type === "date") mpCol.cellDataType = 'dateString';
        else {
          mpCol.cellDataType = 'text';
          mpCol.enableRowGroup = pivotState;
          mpCol.enablePivot = pivotState;
        }
        return mpCol;
      })
      setLinelistColDef(generateColumnGrouping(colGrpLinelist, temp));
    }
  }, [pivotState]);
  useEffect(() => {
    if (isGridReady) {
      if (varSorting !== null && varSorting !== undefined)
        gridApi.current.applyColumnState({ state: varSorting });
      if (varFilters !== null && varFilters !== undefined)
        gridApi.current.setFilterModel(varFilters);
    }
  }, [isGridReady]);
  useEffect(() => {
    if (LinelistData && LinelistData.length > 0) {
      if (isGridReady) {
        calculateAllFormulas();
        calcBottomRow();
      }
    }
  }, [LinelistData]);
  useEffect(() => {
    highlightCells.current = LineListHighlistCell;
    if (isGridReady) gridApi.current.refreshCells({ force: true })
  }, [LineListHighlistCell])
  useEffect(() => {
    if (!refDeliveryMaster || Object.keys(gridApi.current).length <= 0) return
    if (!refDeliveryMasterChange || refDeliveryMasterChange.length <= 0) return
    setResetFilter("reset")
    const arr = highlightCells.current
    const changes = []
    const columns = []
    refDeliveryMasterChange.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refDeliveryMaster.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })
    gridApi.current.forEachNode((event) => {
      const row_id = event.data.row_id;
      const DeliveryMonth = (event.data["Delivery Month"] || "").toLowerCase();
      const SeasonCode = (event.data["Season Code"] || "").toLowerCase();
      if (columns.includes("Delivery Date/ In DC") && fxcalc("Delivery Date/ In DC")) {
        if (DeliveryMonth !== "") {
          const ch = changes.filter((element) =>
            (element["Month"] || "").toLowerCase() === DeliveryMonth &&
            (element["Season Code"].toLowerCase() || "") === SeasonCode
          )
          if (ch.length > 0) {
            event.data["Delivery Date/In DC"] = calculateDeliveryDateInDC(event);
            arr.push({ [tbl_name + "|Delivery Date/In DC|" + row_id]: 1 })
          }
        }
      }
      if (columns.includes("Half Year") && fxcalc("Half Year")) {
        if (event.data["Half Year"] !== null && event.data["Half Year"] !== undefined) {
          const ch = changes.filter((element) =>
            (element["Month"] || "").toLowerCase() === DeliveryMonth &&
            (element["Season Code"].toLowerCase() || "") === SeasonCode
          )
          if (ch.length > 0) {
            event.data["Half Year"] = calculateHalfYear(event);
            arr.push({ [tbl_name + "|Half Year|" + row_id]: 1 })
          }
        }
      }
      if (columns.includes("Delivery Period") && fxcalc("Delivery Period")) {
        if (event.data["Delivery Period"] !== null && event.data["Delivery Period"] !== undefined) {
          const ch = changes.filter((element) =>
            (element["Month"] || "").toLowerCase() === DeliveryMonth &&
            (element["Season Code"].toLowerCase() || "") === SeasonCode
          );
          if (ch.length > 0) {
            event.data["Delivery Period"] = calculateDeliveryPeriod(event);
            arr.push({ [tbl_name + "|Delivery Period|" + row_id]: 1 })
          }
        }
      }
      if (columns.includes("In Store") && fxcalc("In Store")) {
        if (event.data["In Store"] !== null && event.data["In Store"] !== undefined) {
          const ch = changes.filter((element) =>
            (element["Month"].toLowerCase() || "") === DeliveryMonth &&
            (element["Season Code"].toLowerCase() || "") === SeasonCode
          );
          if (ch.length > 0) {
            event.data["In Store"] = calculateInStore(event);
            arr.push({ [tbl_name + "|In Store|" + row_id]: 1 })
          }
        }
      }
    })
    setResetFilter("set")
    highlightCells.current = arr
    gridApi.current.refreshCells({ force: true });
  }, [refDeliveryMaster, refDeliveryMasterChange]);
  useEffect(() => {
    if (!refFBFtyDatabase || Object.keys(gridApi.current).length <= 0) return
    if (!refFBFtyDatabaseChange || refFBFtyDatabaseChange.length <= 0) return
    setResetFilter("reset")
    const arr = highlightCells.current
    const changes = []
    const columns = []
    refFBFtyDatabaseChange.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refFBFtyDatabase.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })
    gridApi.current.forEachNode((event) => {
      const row_id = event.data.row_id
      if (columns.includes("Factory Country") && fxcalc("COO")) {
        if (event.data["Factory"] !== null && event.data["Factory"] !== undefined || event.data["Factory Override"] !== null && event.data["Factory Override"] !== undefined) {
          const ch = changes.filter((element) => (element["Factory"].toLowerCase() === event.data["Factory"].toLowerCase()) || (element["Factory"].toLowerCase() === event.data["Factory Override"].toLowerCase()))
          if (ch.length > 0) {
            event.data["COO"] = calculateCOO(event);
            arr.push({ [tbl_name + "|COO|" + row_id]: 1 })
          }
        }
      }
      if (columns.includes("Commission Percent") && fxcalc("Agent Commission Rate")) {
        if (event.data["Factory"] !== null && event.data["Factory"] !== undefined || event.data["Factory Override"] !== null && event.data["Factory Override"] !== undefined) {
          const ch = changes.filter((element) => (element["Factory"].toLowerCase() === event.data["Factory"].toLowerCase()) || (element["Factory"].toLowerCase() === event.data["Factory Override"].toLowerCase()))
          if (ch.length > 0) {
            event.data["Agent Commission Rate"] = calculateAgentCommissionRate(event);
            arr.push({ [tbl_name + "|Agent Commission Rate|" + row_id]: 1 })
          }
        }
      }
      if (columns.includes("Corporate/Agent") && fxcalc("Corporate/Agent")) {
        if (event.data["Factory"] !== null && event.data["Factory"] !== undefined || event.data["Factory Override"] !== null && event.data["Factory Override"] !== undefined) {
          const ch = changes.filter((element) => (element["Factory"].toLowerCase() === event.data["Factory"].toLowerCase()) || (element["Factory"].toLowerCase() === event.data["Factory Override"].toLowerCase()))
          if (ch.length > 0) {
            event.data["Corporate/Agent"] = calculateCorporateAgent(event);
            arr.push({ [tbl_name + "|Corporate/Agent|" + row_id]: 1 })
          }
        }
      }
      if (columns.includes("FOB/DDP/LDP") && fxcalc("Shipping Terms (FOB/DDP/LDP)")) {
        if (event.data["Factory"] !== null && event.data["Factory"] !== undefined || event.data["Factory Override"] !== null && event.data["Factory Override"] !== undefined) {
          const ch = changes.filter((element) => (element["Factory"].toLowerCase() === event.data["Factory"].toLowerCase()) || (element["Factory"].toLowerCase() === event.data["Factory Override"].toLowerCase()))
          if (ch.length > 0) {
            event.data["Shipping Terms (FOB/DDP/LDP)"] = calculateShippingTermsFOBDDPLDP(event);
            arr.push({ [tbl_name + "|Shipping Terms (FOB/DDP/LDP)|" + row_id]: 1 })
          }
        }
      }
    })
    setResetFilter("set")
    highlightCells.current = arr
    gridApi.current.refreshCells({ force: true });
  }, [refFBFtyDatabase, refFBFtyDatabaseChange]);
  useEffect(() => {
    if (!refFreightMaster || Object.keys(gridApi.current).length <= 0) return
    if (!refFreightMasterChange || refFreightMasterChange.length <= 0) return
    setResetFilter("reset")
    const changes = []
    const columns = []
    refFreightMasterChange.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refFreightMaster.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })
    const arr = highlightCells.current
    gridApi.current.forEachNode((event) => {
      const row_id = event.data.row_id
      if (columns.includes("Ocean Transit Days") && fxcalc("Transit Days")) {
        if (event.data["COO"] !== null && event.data["COO"] !== undefined) {
          const ch = changes.filter((element) => element && event.data && element["COO"].toLowerCase() === event.data["COO"].toLowerCase() &&
            element["Season"].toLowerCase() === event.data["Season Code"].toLowerCase())
          if (ch.length > 0) {
            event.data["Transit Days"] = calculateTransitDays(event);
            arr.push({ [tbl_name + "|Transit Days|" + row_id]: 1 })
          }
        }
      }
      if (columns.includes("CFS Cut Off (Day of Week)") && fxcalc("CFS Cut Off (Day of week)")) {
        if (event.data["COO"] !== null && event.data["COO"] !== undefined) {
          const ch = changes.filter((element) => element && event.data && element["COO"].toLowerCase() === event.data["COO"].toLowerCase() &&
            element["Season"].toLowerCase() === event.data["Season Code"].toLowerCase())
          if (ch.length > 0) {
            event.data["CFS Cut Off (Day of week)"] = calculateCFSCutOffDayofweek(event);
            arr.push({ [tbl_name + "|CFS Cut Off (Day of week)|" + row_id]: 1 })
          }
        }
      }
    })
    setResetFilter("set")
    highlightCells.current = arr
    gridApi.current.refreshCells({ force: true });
  }, [refFreightMaster, refFreightMasterChange]);
  useEffect(() => {
    if (!refFreightByCategory || !refFreightMaster || Object.keys(gridApi.current).length <= 0) return
    if ((!refFreightMasterChange || refFreightMasterChange.length <= 0) && (!refFreightByCategoryChange || refFreightByCategoryChange.length <= 0)) return
    setResetFilter("reset")
    const changes = []
    const columns = []
    refFreightMasterChange.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refFreightMaster.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })
    refFreightByCategoryChange.map((element) => {
      const keys = Object.keys(element);
      const key = keys[0]
      const tmp = key.split("|")
      const dt = refFreightByCategory.filter((item) => item.row_id === tmp[2])
      changes.push(dt[0])
      columns.push(tmp[1])
    })
    const arr = highlightCells.current
    gridApi.current.forEachNode((event) => {
      const row_id = event.data.row_id
      if (columns.includes("AIR")) {
        if (event.data["COO"] !== null && event.data["COO"] !== undefined) {
          const ch = changes.filter((element) => element["COO"].toLowerCase() === event.data["COO"].toLowerCase() &&
            element["Season"].toLowerCase() === event.data["Season Code"].toLowerCase())
          if (ch.length > 0) {
            if (fxcalc("Air Freight")) {
              event.data["Air Freight"] = calculateAirFreight(event);
              arr.push({ [tbl_name + "|Air Freight|" + row_id]: 1 });
            }
            if (fxcalc("Sea Freight")) {
              event.data["Sea Freight"] = calculateSeaFreight(event);
              arr.push({ [tbl_name + "|Sea Freight|" + row_id]: 1 });
            }
          }
        }
      }
      if (columns.includes("Final KG")) {
        if (event.data["Category"] !== null && event.data["Category"] !== undefined) {
          const ch = changes.filter((element) => element["Category"].toLowerCase() === event.data["Category"].toLowerCase() &&
            element["Season"].toLowerCase() === event.data["Season Code"].toLowerCase())
          if (ch.length > 0) {
            if (fxcalc("Air Freight")) {
              event.data["Air Freight"] = calculateAirFreight(event);
              arr.push({ [tbl_name + "|Air Freight|" + row_id]: 1 });
            }
            if (fxcalc("Sea Freight")) {
              event.data["Sea Freight"] = calculateSeaFreight(event);
              arr.push({ [tbl_name + "|Sea Freight|" + row_id]: 1 });
            }
          }
        }
      }
    })
    setResetFilter("set");
    highlightCells.current = arr;
    gridApi.current.refreshCells({ force: true });
  }, [refFreightByCategory, refFreightMaster, refFreightMasterChange, refFreightByCategoryChange]);
  const onGridReady = (params) => {
    gridApi.current = params.api
    gridColumnApi.current = params.columnApi;
    setCurrentGridApi(params);
    calculateAllFormulas();

    setIsGridReady(true)
  };
  const fxcalc = (colnm) => {
    let retval = false;
    if (FormulaCalcColLst.includes(colnm)) retval = true;
    return retval;
  };
  const calculateAllFormulas = () => {
    if (FormulaCalcColLst.length > 0 && sessionStorage.getItem("user_type") !== "read-only") {
      gridApi.current.forEachNode((event) => { calculateReactFormula(event) });
      gridApi.current.refreshCells({ force: true });
    }
    dispatch({ type: "SET_PLEASE_WAIT", data: false });
  };
  const calculateReactFormula = (event) => {
    if (!event.data) return "";
    fxcalc("SKU") ? event.data["SKU"] = calculateSKU(event) : null;
    fxcalc("Working Cost") ? event.data["Working Cost"] = calculateWorkingCost(event) : null;
    fxcalc("Current FOB") ? event.data["Current FOB"] = calculateCurrentFOB(event) : null;
    fxcalc("Current FOB Status") ? event.data["Current FOB Status"] = calculateCurrentFOBStatus(event) : null;
    fxcalc("Merch Fee") ? event.data["Merch Fee"] = calculateMerchFee(event) : null;
    fxcalc("Harbor Maintenance Fee") ? event.data["Harbor Maintenance Fee"] = calculateHarborMaintenanceFee(event) : null;
    fxcalc("COO") ? event.data["COO"] = calculateCOO(event) : null;
    fxcalc("Section 301") ? event.data["Section 301"] = calculateSection301(event) : null;
    fxcalc("SKU Count") ? event.data["SKU Count"] = calculateSKUCount(event) : null;
    fxcalc("Total Projections") ? event.data["Total Projections"] = calculateTotalProjections(event) : null;
    fxcalc("DTC Units Total") ? event.data["DTC Units Total"] = calculateDTCUnitsTotal(event) : null;
    fxcalc("Brand Units Total") ? event.data["Brand Units Total"] = calculateBrandUnitsTotal(event) : null;
    fxcalc("Brand Chase Units") ? event.data["Brand Chase Units"] = calculateBrandChaseUnits(event) : null;
    fxcalc("Total DTC SLS $") ? event.data["Total DTC SLS $"] = calculateTotalDTCSLS(event) : null;
    fxcalc("Total WHSL SLS $") ? event.data["Total WHSL SLS $"] = calculateTotalWHSLSLS(event) : null;
    fxcalc("Total Brand SLS $") ? event.data["Total Brand SLS $"] = calculateTotalWBrandSLS(event) : null;
    fxcalc("Duty Total") ? event.data["Duty Total"] = calculateDutyTotal(event) : null;
    fxcalc("Air Freight") ? event.data["Air Freight"] = calculateAirFreight(event) : null;
    fxcalc("Sea Freight") ? event.data["Sea Freight"] = calculateSeaFreight(event) : null;
    fxcalc("Blended Freight") ? event.data["Blended Freight"] = calculateBlendedFreight(event) : null;
    fxcalc("Scenario LDP") ? event.data["Scenario LDP"] = calculateScenarioLDP(event) : null;
    fxcalc("Agent Commission Rate") ? event.data["Agent Commission Rate"] = calculateAgentCommissionRate(event) : null;
    fxcalc("300 - LDP") ? event.data["300 - LDP"] = calculate300LDP(event) : null;
    fxcalc("500 - LDP") ? event.data["500 - LDP"] = calculate500LDP(event) : null;
    fxcalc("1000 - LDP") ? event.data["1000 - LDP"] = calculate1000LDP(event) : null;
    fxcalc("LDP") ? event.data["LDP"] = calculateLDP(event) : null;
    fxcalc("Current LDP - Status") ? event.data["Current LDP - Status"] = event.data["Current FOB Status"] : null;
    fxcalc("300 - IMU%") ? event.data["300 - IMU%"] = calculate300IMU(event) : null;
    fxcalc("500 - IMU%") ? event.data["500 - IMU%"] = calculate500IMU(event) : null;
    fxcalc("1000 - IMU%") ? event.data["1000 - IMU%"] = calculate1000IMU(event) : null;
    fxcalc("RTL IMU%") ? event.data["RTL IMU%"] = calculateRTLIMU(event) : null;
    fxcalc("WHLS IMU%") ? event.data["WHLS IMU%"] = calculateWHLSIMU(event) : null;
    fxcalc("DTC Units Total + DTC Chase Units") ? event.data["DTC Units Total + DTC Chase Units"] = calculateDTCUnitsTTLChsUnits(event) : null;
    fxcalc("Wholesale Units Total + Wholesale Chase Units") ? event.data["Wholesale Units Total + Wholesale Chase Units"] = calculateWHSLUnitsTTLChsUnits(event) : null;
    fxcalc("Brand Total Including Chase") ? event.data["Brand Total Including Chase"] = calculateBrndTTLIncldChs(event) : null;
    fxcalc("Brand") ? event.data["Brand"] = calculateBrand(event) : null;
    fxcalc("Delivery Date/In DC") ? event.data["Delivery Date/In DC"] = calculateDeliveryDateInDC(event) : null;
    fxcalc("Transit Days") ? event.data["Transit Days"] = calculateTransitDays(event) : null;
    fxcalc("Target X-fty Date") ? event.data["Target X-fty Date"] = calculateTargetXftyDate(event) : null;
    fxcalc("In DC Date (Ocean)") ? event.data["In DC Date (Ocean)"] = calculateInDCDateOcean(event) : null;
    fxcalc("Days Late") ? event.data["Days Late"] = calculateDaysLate(event) : null;
    fxcalc("CBM Per Carton") ? event.data["CBM Per Carton"] = calculateCBMPerCarton(event) : null;
    fxcalc("CBM Per Unit") ? event.data["CBM Per Unit"] = calculateCBMPerUnit(event) : null;
    fxcalc("KG Per Unit") ? event.data["KG Per Unit"] = calculateKGPerUnit(event) : null;
    fxcalc("Half Year") ? event.data["Half Year"] = calculateHalfYear(event) : null;
    fxcalc("Corporate/Agent") ? event.data["Corporate/Agent"] = calculateCorporateAgent(event) : null;
    fxcalc("Shipping Terms (FOB/DDP/LDP)") ? event.data["Shipping Terms (FOB/DDP/LDP)"] = calculateShippingTermsFOBDDPLDP(event) : null;
    fxcalc("CFS Cut Off (Day of week)") ? event.data["CFS Cut Off (Day of week)"] = calculateCFSCutOffDayofweek(event) : null;
    fxcalc("Delivery Period") ? event.data["Delivery Period"] = calculateDeliveryPeriod(event) : null;
    fxcalc("In Store") ? event.data["In Store"] = calculateInStore(event) : null;
    fxcalc("Minimum Met") ? event.data["Minimum Met"] = calculateMinimumMet(event) : null;
    fxcalc("Cost Under Min") ? event.data["Cost Under Min"] = calculateCostUnderMin(event) : null;
    fxcalc("Cost Under Min - Estimated") ? event.data["Cost Under Min - Estimated"] = calculateCostUnderMinEstimated(event) : null;
    fxcalc("Scenario RTL IMU%") ? event.data["Scenario RTL IMU%"] = calculateScenarioRTLIMU(event) : null;
    fxcalc("Scenario WHLS IMU%") ? event.data["Scenario WHLS IMU%"] = calculateScenarioWHLSIMU(event) : null;
    fxcalc("Working Vs Scenario RTL IMU%") ? event.data["Working Vs Scenario RTL IMU%"] = calculateWorkingVsScenarioRTLIMU(event) : null;
    fxcalc("Working Vs Scenario WHSL IMU%") ? event.data["Working Vs Scenario WHSL IMU%"] = calculateWorkingVsScenarioWHSLIMU(event) : null;
    fxcalc("DTC BOP + DTC Total Units") ? event.data["DTC BOP + DTC Total Units"] = calculateDTCBOPDTCTotalUnits(event) : null;
    fxcalc("DTC BOP (Merch View)") ? event.data["DTC BOP (Merch View)"] = calculateMerchColumn(event, "DTC BOP") : null;
    fxcalc("DTC BOP + DTC Total Units (Merch View)") ? event.data["DTC BOP + DTC Total Units (Merch View)"] = calculateMerchColumn(event, "DTC BOP + DTC Total Units") : null;
    fxcalc("Stores (Merch View)") ? event.data["Stores (Merch View)"] = calculateMerchColumn(event, "Stores") : null;
    fxcalc("Ecomm (Merch View)") ? event.data["Ecomm (Merch View)"] = calculateMerchColumn(event, "Ecomm") : null;
    fxcalc("DTC Units Total (Merch View)") ? event.data["DTC Units Total (Merch View)"] = calculateMerchColumn(event, "DTC Units Total") : null;
    fxcalc("DTC Chase Units (Merch View)") ? event.data["DTC Chase Units (Merch View)"] = calculateMerchColumn(event, "DTC Chase Units") : null;
    fxcalc("DTC Units Total + DTC Chase Units (Merch View)") ? event.data["DTC Units Total + DTC Chase Units (Merch View)"] = calculateMerchColumn(event, "DTC Units Total + DTC Chase Units") : null;


  };
  const performProductionLock = () => {
    let asRequiredDownloadQuery = sessionStorage.getItem('asRequiredDownloadQuery')
    if (asRequiredDownloadQuery === null || asRequiredDownloadQuery === undefined || asRequiredDownloadQuery.trim() === "") {
      toastMessage("Please first perform Download Linelist to perform this operation.")
      return
    }
    let lstSKU = new Array()
    gridApi.current.forEachNode((nd) => {
      if (nd.data["DTC Confirmation"] === "Confirmed") {
        if (nd.data["Wholesale Confirmation"] === "Confirmed") {
          if (nd.data["Production Lock"] === "Locked") {
            if (nd.data["Change Flag"] === null || nd.data["Change Flag"] === undefined || nd.data["Change Flag"].trim() === "") {
              if (lstSKU.includes(nd.data["SKU"]) === false) {
                lstSKU.push(nd.data["SKU"])
              }
            }
          }
        }
      }
    })
    if (lstSKU === null || lstSKU === undefined || lstSKU.length === 0) {
      return
    }
    let colMp = new Map()
    colMp.set("SKU", "SKU - Lock")
    colMp.set("Stores", "Stores - Lock")
    colMp.set("Ecomm", "Ecomm - Lock")
    colMp.set("DTC Units Total", "DTC Units Total - Lock")
    colMp.set("Wholesale Units Total", "Wholesale Units Total - Lock")
    colMp.set("Brand Units Total", "Brand Units Total - Lock")
    gridApi.current.forEachNode((nd) => {
      if (nd.data["DTC Confirmation"] === "Confirmed") {
        if (nd.data["Wholesale Confirmation"] === "Confirmed") {
          if (nd.data["Production Lock"] === "Locked") {
            if (nd.data["Change Flag"] === null || nd.data["Change Flag"] === undefined || nd.data["Change Flag"].trim() === "") {
              let tmpCnt = 0
              let lockColVl = null
              for (let [k, v] of colMp) {
                lockColVl = nd.data[v]
                if (lockColVl === null || lockColVl === undefined || lockColVl === "") {
                  nd.data[v] = nd.data[k]
                  tmpCnt = tmpCnt + 1
                }
                lockColVl = null
              }
              if (tmpCnt > 0) {
                // nd.setDataValue((tbl_name + CH1 + "Locked Date"), JsDateToExcelDateNumber(getCurrentDateOnlyAsYyyyMmDd(new Date())))
                nd.setDataValue((tbl_name + CH1 + "Locked Date"), getCurrentDateOnlyAsYyyyMmDd(new Date()))
              }
              tmpCnt = 0
            }
          }
        }
      }
    })
  };
  const performAllowChanges = () => {
    let asRequiredDownloadQuery = sessionStorage.getItem('asRequiredDownloadQuery')
    if (asRequiredDownloadQuery === null || asRequiredDownloadQuery === undefined || asRequiredDownloadQuery.trim() === "") {
      toastMessage("Please first perform Download Linelist to perform this operation.")
      return
    }
    let lstSKU = new Array()
    gridApi.current.forEachNode((nd) => {
      if (nd.data["DTC Confirmation"] === "Confirmed") {
        if (nd.data["Wholesale Confirmation"] === "Confirmed") {
          if (nd.data["Production Lock"] === "Allow Changes") {
            if (nd.data["Change Flag"] !== null && nd.data["Change Flag"] !== undefined && nd.data["Change Flag"].trim() !== "") {
              if (lstSKU.includes(nd.data["SKU"]) === false) {
                lstSKU.push(nd.data["SKU"])
              }
            }
          }
        }
      }
    })
    if (lstSKU === null || lstSKU === undefined || lstSKU.length === 0) {
      return
    }
    let colMp = new Map()
    colMp.set("SKU", "SKU - Lock")
    colMp.set("Stores", "Stores - Lock")
    colMp.set("Ecomm", "Ecomm - Lock")
    colMp.set("DTC Units Total", "DTC Units Total - Lock")
    colMp.set("Wholesale Units Total", "Wholesale Units Total - Lock")
    colMp.set("Brand Units Total", "Brand Units Total - Lock")
    gridApi.current.forEachNode((nd) => {
      if (nd.data["DTC Confirmation"] === "Confirmed") {
        if (nd.data["Wholesale Confirmation"] === "Confirmed") {
          if (nd.data["Production Lock"] === "Allow Changes") {
            if (nd.data["Change Flag"] !== null && nd.data["Change Flag"] !== undefined && nd.data["Change Flag"].trim() !== "") {
              for (let [k, v] of colMp) {
                nd.data[v] = nd.data[k]
                nd.setDataValue((tbl_name + CH1 + "Change Flag"), "")
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "Locked")
                // nd.setDataValue((tbl_name + CH1 + "Locked Date"), JsDateToExcelDateNumber(getCurrentDateOnlyAsYyyyMmDd(new Date())))
                nd.setDataValue((tbl_name + CH1 + "Locked Date"), getCurrentDateOnlyAsYyyyMmDd(new Date()))
              }
            }
          }
        }
      }
    })
  };
  const populateLinelistSKULock = () => {
    let asRequiredDownloadQuery = sessionStorage.getItem('asRequiredDownloadQuery')
    if (asRequiredDownloadQuery === null || asRequiredDownloadQuery === undefined || asRequiredDownloadQuery.trim() === "") {
      toastMessage("Please first perform Download Linelist to perform this operation.")
      return
    }
    gridApi.current.forEachNode((nd) => {
      if (nd.data["Production Lock"] !== "Locked") {
        nd.data["SKU - Lock"] = nd.data["SKU"]
      }
    })
  };
  const checkProductionLockValidations = () => {
    let asRequiredDownloadQuery = sessionStorage.getItem('asRequiredDownloadQuery')
    if (asRequiredDownloadQuery === null || asRequiredDownloadQuery === undefined || asRequiredDownloadQuery.trim() === "") {
      toastMessage("Please first perform Download Linelist to perform this operation.")
      return
    }
    gridApi.current.forEachNode((nd) => {
      let DTC_Confirmation_Vl = null
      let Wholesale_Confirmation_Vl = null
      let Change_Flag_Vl = null
      let Stores_Lock_Vl = null
      DTC_Confirmation_Vl = nd.data["DTC Confirmation"]
      Wholesale_Confirmation_Vl = nd.data["Wholesale Confirmation"]
      Change_Flag_Vl = nd.data["Change Flag"]
      Stores_Lock_Vl = nd.data["Stores - Lock"]
      if (nd.data["Production Lock"] === "Locked") {
        // For Locked Status
        if (DTC_Confirmation_Vl === null || DTC_Confirmation_Vl === undefined || DTC_Confirmation_Vl.trim() === "") {
          if (Wholesale_Confirmation_Vl === null || Wholesale_Confirmation_Vl === undefined || Wholesale_Confirmation_Vl.trim() === "") {
            if (Change_Flag_Vl === null || Change_Flag_Vl === undefined || Change_Flag_Vl.trim() === "") {
              if (Stores_Lock_Vl === null || Stores_Lock_Vl === undefined || Stores_Lock_Vl.trim() === "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "")
                unsentCount--;
              }
            }
          }
        }
        if (DTC_Confirmation_Vl === "Confirmed" || DTC_Confirmation_Vl === "Planning" || DTC_Confirmation_Vl === "Do Not Place Order") {
          if (Wholesale_Confirmation_Vl === null || Wholesale_Confirmation_Vl === undefined || Wholesale_Confirmation_Vl.trim() === "") {
            if (Change_Flag_Vl === null || Change_Flag_Vl === undefined || Change_Flag_Vl.trim() === "") {
              if (Stores_Lock_Vl === null || Stores_Lock_Vl === undefined || Stores_Lock_Vl.trim() === "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "")
                unsentCount--;
              }
            }
          }
        }
        if (DTC_Confirmation_Vl === "Confirmed" || DTC_Confirmation_Vl === "Planning" || DTC_Confirmation_Vl === "Do Not Place Order") {
          if (Wholesale_Confirmation_Vl === null || Wholesale_Confirmation_Vl === undefined || Wholesale_Confirmation_Vl.trim() === "") {
            if (Change_Flag_Vl !== null && Change_Flag_Vl !== undefined && Change_Flag_Vl.trim() !== "") {
              if (Stores_Lock_Vl === null || Stores_Lock_Vl === undefined || Stores_Lock_Vl.trim() === "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "")
                unsentCount--;
              }
            }
          }
        }
        if (DTC_Confirmation_Vl === null || DTC_Confirmation_Vl === undefined || DTC_Confirmation_Vl.trim() === "") {
          if (Wholesale_Confirmation_Vl === null || Wholesale_Confirmation_Vl === undefined || Wholesale_Confirmation_Vl.trim() === "") {
            if (Change_Flag_Vl !== null && Change_Flag_Vl !== undefined && Change_Flag_Vl.trim() !== "") {
              if (Stores_Lock_Vl === null || Stores_Lock_Vl === undefined || Stores_Lock_Vl.trim() === "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "")
                unsentCount--;
              }
            }
          }
        }
        if (DTC_Confirmation_Vl === null || DTC_Confirmation_Vl === undefined || DTC_Confirmation_Vl.trim() === "") {
          if (Wholesale_Confirmation_Vl === "Confirmed" || Wholesale_Confirmation_Vl === "Planning" || Wholesale_Confirmation_Vl === "Do Not Place Order") {
            if (Change_Flag_Vl === null || Change_Flag_Vl === undefined || Change_Flag_Vl.trim() === "") {
              if (Stores_Lock_Vl === null || Stores_Lock_Vl === undefined || Stores_Lock_Vl.trim() === "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "")
                unsentCount--;
              }
            }
          }
        }
        if (DTC_Confirmation_Vl === null || DTC_Confirmation_Vl === undefined || DTC_Confirmation_Vl.trim() === "") {
          if (Wholesale_Confirmation_Vl === "Confirmed" || Wholesale_Confirmation_Vl === "Planning" || Wholesale_Confirmation_Vl === "Do Not Place Order") {
            if (Change_Flag_Vl !== null && Change_Flag_Vl !== undefined && Change_Flag_Vl.trim() !== "") {
              if (Stores_Lock_Vl === null || Stores_Lock_Vl === undefined || Stores_Lock_Vl.trim() === "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "")
                unsentCount--;
              }
            }
          }
        }
      } else if (nd.data["Production Lock"] === "Allow Changes") {
        // For Allow Changes Status
        if (DTC_Confirmation_Vl === null || DTC_Confirmation_Vl === undefined || DTC_Confirmation_Vl.trim() === "") {
          if (Wholesale_Confirmation_Vl === null || Wholesale_Confirmation_Vl === undefined || Wholesale_Confirmation_Vl.trim() === "") {
            if (Change_Flag_Vl === null || Change_Flag_Vl === undefined || Change_Flag_Vl.trim() === "") {
              if (Stores_Lock_Vl === null || Stores_Lock_Vl === undefined || Stores_Lock_Vl.trim() === "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "")
                unsentCount--;
              }
            }
          }
        }
        if (DTC_Confirmation_Vl === "Confirmed" || DTC_Confirmation_Vl === "Planning" || DTC_Confirmation_Vl === "Do Not Place Order") {
          if (Wholesale_Confirmation_Vl === null || Wholesale_Confirmation_Vl === undefined || Wholesale_Confirmation_Vl.trim() === "") {
            if (Change_Flag_Vl === null || Change_Flag_Vl === undefined || Change_Flag_Vl.trim() === "") {
              if (Stores_Lock_Vl === null || Stores_Lock_Vl === undefined || Stores_Lock_Vl.trim() === "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "")
                unsentCount--;
              } else if (Stores_Lock_Vl !== null && Stores_Lock_Vl !== undefined && Stores_Lock_Vl.trim() !== "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "Locked")
                unsentCount--;
              }
            }
          }
        }
        if (DTC_Confirmation_Vl === "Confirmed" || DTC_Confirmation_Vl === "Planning" || DTC_Confirmation_Vl === "Do Not Place Order") {
          if (Wholesale_Confirmation_Vl === null || Wholesale_Confirmation_Vl === undefined || Wholesale_Confirmation_Vl.trim() === "") {
            if (Change_Flag_Vl !== null && Change_Flag_Vl !== undefined && Change_Flag_Vl.trim() !== "") {
              if (Stores_Lock_Vl === null || Stores_Lock_Vl === undefined || Stores_Lock_Vl.trim() === "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "")
                unsentCount--;
              } else if (Stores_Lock_Vl !== null && Stores_Lock_Vl !== undefined && Stores_Lock_Vl.trim() !== "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "Locked")
                unsentCount--;
              }
            }
          }
        }
        if (DTC_Confirmation_Vl === null || DTC_Confirmation_Vl === undefined || DTC_Confirmation_Vl.trim() === "") {
          if (Wholesale_Confirmation_Vl === null || Wholesale_Confirmation_Vl === undefined || Wholesale_Confirmation_Vl.trim() === "") {
            if (Change_Flag_Vl !== null && Change_Flag_Vl !== undefined && Change_Flag_Vl.trim() !== "") {
              if (Stores_Lock_Vl === null || Stores_Lock_Vl === undefined || Stores_Lock_Vl.trim() === "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "")
                unsentCount--;
              } else if (Stores_Lock_Vl !== null && Stores_Lock_Vl !== undefined && Stores_Lock_Vl.trim() !== "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "Locked")
                unsentCount--;
              }
            }
          }
        }
        if (DTC_Confirmation_Vl === null || DTC_Confirmation_Vl === undefined || DTC_Confirmation_Vl.trim() === "") {
          if (Wholesale_Confirmation_Vl === "Confirmed" || Wholesale_Confirmation_Vl === "Planning" || Wholesale_Confirmation_Vl === "Do Not Place Order") {
            if (Change_Flag_Vl === null || Change_Flag_Vl === undefined || Change_Flag_Vl.trim() === "") {
              if (Stores_Lock_Vl === null || Stores_Lock_Vl === undefined || Stores_Lock_Vl.trim() === "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "")
                unsentCount--;
              } else if (Stores_Lock_Vl !== null && Stores_Lock_Vl !== undefined && Stores_Lock_Vl.trim() !== "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "Locked")
                unsentCount--;
              }
            }
          }
        }
        if (DTC_Confirmation_Vl === null || DTC_Confirmation_Vl === undefined || DTC_Confirmation_Vl.trim() === "") {
          if (Wholesale_Confirmation_Vl === "Confirmed" || Wholesale_Confirmation_Vl === "Planning" || Wholesale_Confirmation_Vl === "Do Not Place Order") {
            if (Change_Flag_Vl !== null && Change_Flag_Vl !== undefined && Change_Flag_Vl.trim() !== "") {
              if (Stores_Lock_Vl === null || Stores_Lock_Vl === undefined || Stores_Lock_Vl.trim() === "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "")
                unsentCount--;
              } else if (Stores_Lock_Vl !== null && Stores_Lock_Vl !== undefined && Stores_Lock_Vl.trim() !== "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "Locked")
                unsentCount--;
              }
            }
          }
        }
        if (DTC_Confirmation_Vl === "Confirmed") {
          if (Wholesale_Confirmation_Vl === "Confirmed") {
            if (Change_Flag_Vl === null || Change_Flag_Vl === undefined || Change_Flag_Vl.trim() === "") {
              if (Stores_Lock_Vl === null || Stores_Lock_Vl === undefined || Stores_Lock_Vl.trim() === "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "")
                unsentCount--;
              } else if (Stores_Lock_Vl !== null && Stores_Lock_Vl !== undefined && Stores_Lock_Vl.trim() !== "") {
                nd.setDataValue((tbl_name + CH1 + "Production Lock"), "Locked")
                unsentCount--;
              }
            }
          }
        }
      }
      DTC_Confirmation_Vl = null
      Wholesale_Confirmation_Vl = null
      Change_Flag_Vl = null
      Stores_Lock_Vl = null
      let DTC_Confirmation_Final = ((nd.data["DTC Confirmation"] === null || nd.data["DTC Confirmation"] === undefined || nd.data["DTC Confirmation"].trim() === '') ? '' : nd.data["DTC Confirmation"])
      let Wholesale_Confirmation_Final = ((nd.data["Wholesale Confirmation"] === null || nd.data["Wholesale Confirmation"] === undefined || nd.data["Wholesale Confirmation"].trim() === '') ? '' : nd.data["Wholesale Confirmation"])
      if (!(DTC_Confirmation_Final === "Confirmed" && Wholesale_Confirmation_Final === "Confirmed")) {
        nd.setDataValue((tbl_name + CH1 + "Production Lock"), "")
        unsentCount--;
      }
      DTC_Confirmation_Final = null
      Wholesale_Confirmation_Final = null
    })
    if (unsentCount < 0) unsentCount = 0;
    dispatch({ type: "SET_LINELIST_UNSENT_COUNT", count: unsentCount });
  };
  const calcBottomRow = () => {
    const result = {};
    let filtredData = [];
    footerRowCalCols.forEach(c => { if (!result[c]) result[c] = { btmRow1: 0, btmRow2: 0 } });
    try { gridApi.current.forEachNodeAfterFilter(node => { filtredData.push(node.data) }); }
    catch { filtredData = LinelistData; }
    filtredData = filtredData.filter((i) => i["Style Activity"] === "ACTIVE");
    const linelistActive = LinelistData.filter((i) => i["Style Activity"] === "ACTIVE");
    const CostingStatus = LinelistData.filter((i) => i["Costing Status"] === "Confirmed FOB");
    const CurrentFOBStatus = LinelistData.filter((i) => i["Current FOB Status"] === "Confirmed");
    const CurrentLDPStatus = LinelistData.filter((i) => i["Current LDP - Status"] === "Confirmed");
    const DeliveryStatus = LinelistData.filter((i) => i["Delivery Status"] === "Confirmed X-Fty");
    footerRowCalCols.forEach(column => {
      if (column === "Style Activity") {
        result["Style Activity"].btmRow1 = LinelistData.length;
        result["Style Activity"].btmRow2 = filtredData.length;
      } else if (column === "Costing Status") {
        result["Costing Status"].btmRow1 = "Confirmed Costing";
        result["Costing Status"].btmRow2 = ((CostingStatus.length / LinelistData.length) * 100).toFixed(2) + "%";
      } else if (column === "Current FOB Status") {
        result["Current FOB Status"].btmRow1 = "Confirmed FOB Status";
        result["Current FOB Status"].btmRow2 = ((CurrentFOBStatus.length / LinelistData.length) * 100).toFixed(2) + "%";
      } else if (column === "Current LDP - Status") {
        result["Current LDP - Status"].btmRow1 = "Confirmed LDP Status";
        result["Current LDP - Status"].btmRow2 = ((CurrentLDPStatus.length / LinelistData.length) * 100).toFixed(2) + "%";
      } else if (column === "Delivery Status") {
        result["Delivery Status"].btmRow1 = "Confirmed Delivery";
        result["Delivery Status"].btmRow2 = ((DeliveryStatus.length / LinelistData.length) * 100).toFixed(2) + "%";
      } else {
        // result[column].btmRow1 = linelistActive.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue[column] ? parseFloat(currentValue[column]).toFixed(2) : 0), 0);
        // result[column].btmRow2 = filtredData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue[column] ? parseFloat(currentValue[column]).toFixed(2) : 0), 0);
        const total1 = linelistActive.reduce((accumulator, currentValue) => {
          const value = parseFloat(currentValue[column]);
          return accumulator + (isNaN(value) ? 0 : value);
        }, 0);
        const count1 = linelistActive.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue[column] ? 1 : 0);
        }, 0);
        const average1 = count1 > 0 ? (total1 / count1).toFixed(2) : 0;
        result[column].btmRow1 = average1;

        const total2 = filtredData.reduce((accumulator, currentValue) => {
          const value2 = parseFloat(currentValue[column]);
          return accumulator + (isNaN(value2) ? 0 : value2);
        }, 0);
        const count2 = filtredData.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue[column] ? 1 : 0);
        }, 0);
        const average2 = count2 > 0 ? (total2 / count2).toFixed(2) : 0;
        result[column].btmRow2 = average2;
      }
    });
    if (Object.keys(result).length === 0) setBottomRowData([]);
    else {
      let row1 = [];
      let row2 = [];
      footerRowCalCols.forEach(c => {
        row1[c] = `${result[c]["btmRow1"]}`
        row2[c] = `${result[c]["btmRow2"]}`
      });
      setBottomRowData([row1, row2]);
    }

  };
  const resteAutoChangeState = () => {
    setResetFilter("set");
    setAutoChangeState(false);
    setAutoGetChangeResult({});
  };
  const setCellValueChanged = (data) => {
    cellValueChanged.current = data;
  };
  const onPivotModeChanged = () => {
    const isPivotMode = gridApi.current.isPivotMode()
    if (!isPivotMode) {
      setPivotState(false)
      const columnState = gridApi.current.getColumnState();
      const rowGroupState = gridApi.current.getColumnGroupState();
      const pivotRowGroupState = {
        columnState,
        rowGroupState,
        "test": "testing"
      };
      dispatch({ type: "llTabPvtData", data: pivotRowGroupState });
      gridApi.current.setRowGroupColumns([]);
    }
    else {
      // setPivotState( isPivotMode)
      setPivotState(true)
      if (llTabPvtData?.columnState) {
        gridApi.current.applyColumnState({ state: llTabPvtData.columnState, applyOrder: true });
        gridApi.current.setColumnGroupState(llTabPvtData.rowGroupState);
      }
    }
    // const newRowData = isPivotMode ? rowData : LinelistData;
    // gridApi.current.setRowData(newRowData);
    // isPivotMode ? gridApi.current.setColumnDefs(columnDefs) : gridApi.current.setColumnDefs(LinelistColDef)
  };

  return (
    <>
      <CustomAgGrid
        ref={gridRef}
        setCurrentGridApi={onGridReady}
        filters={varFilters}
        setActionItemDataFun={setActionItemDataFun}
        tab_name="Linelist"
        tbl_name={tbl_name}
        columnDefs={tmpColDef}
        customColumnDef={LinelistColDef}
        rowData={LinelistData}
        SSNData={SSNData}
        tblHighlightCells={LineListHighlistCell}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
        autoChangeState={autoChangeState}
        resteAutoChangeState={resteAutoChangeState}
        autoGetChangeResult={autoGetChangeResult}
        setCellValueChanged={setCellValueChanged}
        calculateFormula={calculateReactFormula}
        isGrouppedHeader={true}
        calculateBottomRow={calcBottomRow}
        pinnedBottomRowData={bottomRowData}
        onColumnPivotModeChanged={onPivotModeChanged}
        columnGrpPref={columnGrpPref}
        columnPref={columnPref}
      />
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={PopupImage}
        onClose={() => setPopupImage(false)}
      >
        <DialogTitle
          disableTypography
          style={{ fontWeight: "600", fontSize: "15px" }}
        >
          Product Summary
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} container justifyContent="center">
              <MDBox
                component="img"
                src={`https://mediasvr.sohum.tech/ciimages/ciregularimages/${PopupImageData.image}`}
                style={{
                  maxHeight: "300px",
                  borderRadius: "5%",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <MDBox mb={1}>{PopupImageData.brnd}</MDBox> */}
              {/* <MDBox mb={1}>
                <h5>{PopupImageData.stsku}</h5>
              </MDBox>
              <MDBox mb={1}>
                <h2>{PopupImageData.nm}</h2>
              </MDBox> */}
              <MDBox mb={1}>
                <b>Style : </b>
                {PopupImageData["Style"]}
              </MDBox>
              <MDBox mb={1}>
                <b>Style Description : </b>
                {PopupImageData["Style Description"]}
              </MDBox>
              <MDBox mb={1}>
                <b>Department : </b>
                {PopupImageData["Department"]}
              </MDBox>
              <MDBox mb={1}>
                <b>Color : </b>
                {PopupImageData["Color Description"]}
              </MDBox>
              <MDBox mb={1}>
                <b>Category : </b>
                {PopupImageData["Category"]}
              </MDBox>
              <MDBox mb={1}>
                <b>Subcategory : </b>
                {PopupImageData["Subcategory"]}
              </MDBox>
              <MDBox mb={1}>
                <b>Fabric : </b>
                {PopupImageData["Fabric Content"]}
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} sm={12}>
              <MDBox>
                <b>Description : </b>
                {PopupImageData.description}
              </MDBox>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPopupImage(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
})
LinelistTab.propTypes = {
  setCurrentGridApi: PropTypes.func.isRequired,
  settimeLineDataFun: PropTypes.func.isRequired,
};
export default LinelistTab;