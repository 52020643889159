import { Divider, Tab, Tabs } from "@mui/material";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import breakpoints from "assets/theme/base/breakpoints";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import { setCurrentTab, setDefault, setMiniSidenav, setSideNavBtnClick, useMaterialUIController } from "context";
import { navbarDesktopMenu } from "examples/Navbars/DashboardNavbar/styles";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";
import { functionalityAccess } from "layouts/linelist/customFunctions";
import { toastMessage } from "layouts/linelist/XL_Utils";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { store } from "store";
import { isSideBarEnabled, restApiPath } from "utils/app.props";

// function DefaultNavbar({
const DefaultNavbar = forwardRef(({
  assortBtnCall,
  btnCarryOver,
  btnSavePreference,
  calculateSizesBtnCall,
  costingViewBtnCall,
  defaultCostingViewBtnCall,
  downloadBtn,
  downloadLinelistBtnCall,
  finalCostingView,
  generateItemUpload,
  getChangesBtnCall,
  highlightStyle,
  importDataBtnCall,
  light,
  onQuickFilterChange,
  quickFilterInputRef,
  quickFilterText,
  redownloadTabBtnCall,
  sendChangeMemoBtnCall,
  sendChangesBtnCall,
  ShowChangesBtnCall,
  transparent,
  upload_btn_click,
  summaryDlgOpen,
  ShowGenie,
  ShowHelpPopup,
  activeStyleActivityFilterBtnCall,
  droppedStyleActivityFilterBtnCall,
  allStyleActivityFilterBtnCall
}, ref) => {
  const [controller, dispatchMui] = useMaterialUIController();
  const userRole = sessionStorage.getItem("user_type");
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [clickedMsg, setClickedMsg] = useState({});
  const [openSkuDlg, setOpenSkuDlg] = useState(false);
  const handleSkuOpen = () => {
    setOpenSkuDlg(true);
  };
  const handleSkuClose = () => {
    setOpenSkuDlg(false);
    setOpenNotificationsMenu(false);
  };
  const [actionMsg, setActionMsg] = useState([]);
  const [openNotificationsMenu, setOpenNotificationsMenu] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const { miniSidenav, transparentNavbar, darkMode, currentTab } = controller;
  const redux = store.getState();
  const nevigate = useNavigate();
  const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);
  const dispatch = useDispatch();
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };
  const checkForAppLevelAccess = () => {
    let flag = false;
    const buttons = ["btnDownloadData"];
    buttons.map((btn) => {
      if (functionalityAccess(btn, currentTab)) {
        flag = true;
        return;
      }
    });
    return flag;
  };
  const checkForTabLevelAccess = () => {
    let flag = false;
    const buttons = ["btnRedownloadTab", "btnSendChanges", "btnAssort", "btnCarryOver"];
    buttons.map((btn) => {
      if (functionalityAccess(btn, currentTab)) {
        flag = true;
        return;
      }
    });
    return flag;
  };
  const checkForDownloadLevelAccess = () => {
    let flag = false;
    const buttons = [
      "btnDownloadLinelist",
      "btnDownloadDtc",
      "btnDownloadWholesale",
      "btnDownloadChase",
      "btnDownloadSizing",
      "btnDownloadManageUser"
    ];
    buttons.map((btn) => {
      if (functionalityAccess(btn, currentTab)) {
        flag = true;
        return;
      }
    });
    return flag;
  };
  const checkForUtilityLevelAccess = () => {
    let flag = false;
    const buttons = ["btnShowChanges", "btnSavePreference", "btnImportData"];
    buttons.map((btn) => {
      if (functionalityAccess(btn, currentTab)) {
        flag = true;
        return;
      }
    });
    return flag;
  };
  const checkForOprationLevelAccess = () => {
    let flag = false;
    const buttons = [
      "btnSendChangeMemo",
      "btnGenerateItemUpload",
      "btnHighlightStyle",
      "btnCalculateSizes",
      "btnDocumentUpload",
    ];
    buttons.map((btn) => {
      if (functionalityAccess(btn, currentTab)) {
        flag = true;
        return;
      }
    });
    return flag;
  };
  const checkForCostingViewLevelAccess = () => {
    let flag = false;
    const buttons = [
      "btnDefaultCostingView",
      "btnCostingView",
      "btnFinalCostingView",
    ];
    buttons.map((btn) => {
      if (functionalityAccess(btn, currentTab)) {
        flag = true;
        return;
      }
    });
    return flag;
  };
  const checkForStleActivityViewLevelAccess = () => {
    let flag = false;
    const buttons = [
      "btnStyleActivityView",
    ];
    buttons.map((btn) => {
      if (functionalityAccess(btn, currentTab)) {
        flag = true;
        return;
      }
    });
    return flag;
  };
  const [chkAppLevel, setAppTabLevel] = useState(checkForAppLevelAccess);
  const [chkTabLevel, setChkTabLevel] = useState(checkForTabLevelAccess);
  const [chkDownloadLevel, setChkDownloadLevel] = useState(checkForDownloadLevelAccess);
  const [chkUtilityLevel, setChkUtilityLevel] = useState(checkForUtilityLevelAccess);
  const [chkOperationLevel, setChkOperationLevel] = useState(checkForOprationLevelAccess);
  const [chkCostingView, setCostingView] = useState(checkForCostingViewLevelAccess);
  const [chkStyleActivityView, setStyleActivityView] = useState(checkForStleActivityViewLevelAccess);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }
    window.addEventListener("resize", displayMobileNavbar);
    displayMobileNavbar();
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);
  useEffect(() => {
    setAppTabLevel(checkForAppLevelAccess);
    setChkTabLevel(checkForTabLevelAccess);
    setChkDownloadLevel(checkForDownloadLevelAccess);
    setChkUtilityLevel(checkForUtilityLevelAccess);
    setChkOperationLevel(checkForOprationLevelAccess);
    setCostingView(checkForCostingViewLevelAccess);
    setStyleActivityView(checkForStleActivityViewLevelAccess);
  }, [currentTab, redux.appMetaData]);
  
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;
      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }
      return colorValue;
    },
  });
  const handleMiniSidenav = () => {
    setSideNavBtnClick(dispatchMui, true);
    setMiniSidenav(dispatchMui, !miniSidenav);
  };
  
  return (
    <>
      <MDBox
        id="TOPBUTTONS"
        ml={0.5}
        mt={1}
        width="99%"
        borderRadius="lg"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        // position="absolute"
        left={0}
        // zIndex={99}
        sx={({
          palette: { transparent: transparentColor, white, background },
          functions: { rgba },
        }) => ({
          backgroundColor: transparent
            ? transparentColor.main
            : rgba(darkMode ? background.sidenav : white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            py={transparent ? 1.5 : 0.75}
            lineHeight={1}
            pl={{ xs: 0, lg: 1 }}
            sx={{ whiteSpace: "nowrap" }}
          >
            {isSideBarEnabled ? (
              <IconButton
                sx={navbarDesktopMenu}
                onClick={handleMiniSidenav}
                size="small"
                disableRipple
                style={{ width: "30px", height: "30px", marginLeft: "15px" }}
              >
                <Icon fontSize="medium" sx={iconsStyle}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            ) : null}
          </MDBox>
          <MDBox color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
            <MDBox display="flex" justifyContent="center" width={windowDimensions.width - 60}>
              <Tabs variant="scrollable" scrollButtons={true} centered
                sx={{ "& .MuiTabs-indicator": { display: "none" }, paddingLeft: '0px', paddingRight: '0px', width: "100%", alignContent:"center" }}
              >
                {chkAppLevel ? (
                  <>
                    <div style={{ justifyContent: "center", minWidth: "max-content" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          fontSize: "10px",
                        }}
                      >
                        <span>App Level</span>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        {functionalityAccess("btnDownloadData", currentTab) ? (
                          <Tab
                            icon={<Icon style={{ marginRight: '5px' }}>file_download</Icon>}
                            label="Download Data"
                            onClick={downloadLinelistBtnCall}
                            className="custom-tab"
                          />
                        ) : null}
                      </div>
                    </div>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      style={{ background: "black", marginRight: "3px", marginLeft: "3px" }}
                    />
                  </>
                ) : null}
                {chkTabLevel ? (
                  <>
                    <div>
                      <div style={{ display: "flex", justifyContent: "center", fontSize: "10px" }}>
                        <span>Tab Level</span>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "row", minWidth: "max-content" }}
                        >
                          {functionalityAccess("btnRedownloadTab", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>download_for_offline</Icon>}
                              label="Redownload"
                              onClick={redownloadTabBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnSendChanges", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>arrow_upward</Icon>}
                              label="Send Changes"
                              onClick={sendChangesBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnAssort", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>swap_horiz</Icon>}
                              label="Assort"
                              onClick={assortBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnCarryOver", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>swap_horiz</Icon>}
                              label="Carry Over"
                              onClick={btnCarryOver}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnSummaryView", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>dashboard</Icon>}
                              label="Dashboard"
                              onClick={summaryDlgOpen}
                              className="custom-tab"
                            />
                          ) : null}

                          {/* {buttonAccess("summaryView", userRole) ? <Tab icon={<Dashboard />} label="Summary" onClick={summaryDlgOpen} /> : null} */}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {chkDownloadLevel ? (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      style={{ background: "black", marginRight: "3px", marginLeft: "3px" }}
                    />
                    <div style={{ justifyContent: "center", minWidth: "max-content" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          fontSize: "10px",
                        }}
                      >
                        <span>On-Demand Download</span>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {functionalityAccess("btnDownloadLinelist", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>system_update_alt</Icon>}
                              label="Linelist"
                              onClick={() => downloadBtn("Linelist")}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnDownloadDtc", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>system_update_alt</Icon>}
                              label="DTC Buy"
                              onClick={() => downloadBtn("DTC_Buy_Sheet")}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnDownloadWholesale", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>system_update_alt</Icon>}
                              label="Wholesale Buy"
                              onClick={() => downloadBtn("Wholesale")}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnDownloadChase", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>system_update_alt</Icon>}
                              label="Chase"
                              onClick={() => downloadBtn("Chase")}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnDownloadSizing", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>system_update_alt</Icon>}
                              label="Sizing"
                              onClick={() => downloadBtn("Sizing")}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnDownloadManageUser", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>system_update_alt</Icon>}
                              label="Manage User"
                              onClick={() => downloadBtn("Manage User")}
                              className="custom-tab"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {chkUtilityLevel ? (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      style={{ background: "black", marginRight: "3px", marginLeft: "3px" }}
                    />
                    <div style={{ justifyContent: "center", minWidth: "max-content" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          fontSize: "10px",
                        }}
                      >
                        <span>Utilities</span>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {functionalityAccess("btnShowChanges", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>update</Icon>}
                              label="Show Changes"
                              onClick={ShowChangesBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnSavePreference", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>star</Icon>}
                              label="Preferences"
                              onClick={btnSavePreference}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnImportData", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>import_contacts</Icon>}
                              label="Import"
                              onClick={importDataBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {chkOperationLevel ? (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      style={{ background: "black", marginRight: "3px", marginLeft: "3px" }}
                    />
                    <div>
                      <div style={{ display: "flex", justifyContent: "center", fontSize: "10px" }}>
                        <span>Operations</span>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row", minWidth: "max-content"}}>
                          {functionalityAccess("btnSendChangeMemo", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>forward_to_inbox</Icon>}
                              label="Change Memo"
                              onClick={sendChangeMemoBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnGenerateItemUpload", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>note_add</Icon>}
                              label="Item Upload"
                              onClick={generateItemUpload}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnHighlightStyle", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>format_color_text</Icon>}
                              label="Highlight Style"
                              onClick={highlightStyle}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnCalculateSizes", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>calculate_outlined</Icon>}
                              label="Calculate Sizes"
                              onClick={calculateSizesBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnDocumentUpload", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>attach_file</Icon>}
                              label="Attachment"
                              onClick={upload_btn_click}
                              className="custom-tab"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {chkCostingView ? (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      style={{ background: "black", marginRight: "3px", marginLeft: "3px" }}
                    />
                    <div>
                      <div style={{ display: "flex", justifyContent: "center", fontSize: "10px" }}>
                        <span>Costing View</span>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row",minWidth: "max-content" }}>
                        {functionalityAccess("btnDefaultCostingView", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>calendar_view_month</Icon>}
                              label="Default"
                              onClick={defaultCostingViewBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnCostingView", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>calendar_view_month</Icon>}
                              label="Costing"
                              onClick={costingViewBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnFinalCostingView", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>calendar_view_month</Icon>}
                              label="Final Costing"
                              onClick={finalCostingView}
                              className="custom-tab"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {chkStyleActivityView ? (
                  <>
                    <Divider
                      orientation="vertical"
                      variant="fullWidth"
                      flexItem
                      style={{ background: "black", marginRight: "3px", marginLeft: "3px" }}
                    />
                    <div>
                      <div style={{ display: "flex", justifyContent: "center", fontSize: "10px" }}>
                        <span>Style Activity View</span>
                      </div>
                      <div
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" ,minWidth: "max-content"}}>
                          {functionalityAccess("btnStyleActivityView", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>calendar_view_month</Icon>}
                              label="Active"
                              onClick={activeStyleActivityFilterBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnStyleActivityView", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>calendar_view_month</Icon>}
                              label="Dropped"
                              onClick={droppedStyleActivityFilterBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                          {functionalityAccess("btnStyleActivityView", currentTab) ? (
                            <Tab
                              icon={<Icon style={{ marginRight: '5px' }}>calendar_view_month</Icon>}
                              label="All"
                              onClick={allStyleActivityFilterBtnCall}
                              className="custom-tab"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </Tabs>
            </MDBox>
          </MDBox>
          <MDBox
            display={{ xs: "inline-block", lg: "none" }}
            lineHeight={0}
            py={1.5}
            pl={1.5}
            color={transparent ? "white" : "inherit"}
            sx={{ cursor: "pointer" }}
            onClick={openMobileNavbar}
          >
            <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
          </MDBox>
        </MDBox>
        <MDBox
          bgColor={transparent ? "white" : "transparent"}
          shadow={transparent ? "lg" : "none"}
          borderRadius="md"
          px={transparent ? 2 : 0}
        >
          {mobileView && (
            <DefaultNavbarMobile
              assortBtnCall={assortBtnCall}
              btnCarryOver={btnCarryOver}
              btnSavePreference={btnSavePreference}
              calculateSizesBtnCall={calculateSizesBtnCall}
              costingViewBtnCall={costingViewBtnCall}
              defaultCostingViewBtnCall={defaultCostingViewBtnCall}
              downloadBtn={downloadBtn}
              downloadLinelistBtnCall={downloadLinelistBtnCall}
              finalCostingView={finalCostingView}
              generateItemUpload={generateItemUpload}
              getChangesBtnCall={getChangesBtnCall}
              highlightStyle={highlightStyle}
              importDataBtnCall={importDataBtnCall}
              onQuickFilterChange={onQuickFilterChange}
              open={mobileNavbar}
              quickFilterInputRef={quickFilterInputRef}
              quickFilterText={quickFilterText}
              redownloadTabBtnCall={redownloadTabBtnCall}
              sendChangeMemoBtnCall={sendChangeMemoBtnCall}
              sendChangesBtnCall={sendChangesBtnCall}
              ShowChangesBtnCall={ShowChangesBtnCall}
              upload_btn_click={upload_btn_click}
            />
          )}
        </MDBox>
      </MDBox>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Report Date Range"
        content={notificationMessage}
        dateTime=""
        open={showNotification}
        onClose={() => setShowNotification(false)}
        close={() => setShowNotification(false)}
      />
    </>
  );
});
DefaultNavbar.defaultProps = {
  brand: "Material Dashboard PRO",
  transparent: false,
  light: false,
};
export default DefaultNavbar;
