import { formatDateToMmDdYyyyHhMmSsAmPm } from "layouts/linelist/XL_Utils";
import { ProperCase } from "layouts/linelist/XL_Utils";

export const convertToNumber = (value) => {
    if (isNaN(Number(value)) || value === null || value === undefined || value === "") {
        return 0; // Return a default value like 0 for null, undefined, or non-numeric
    }
    return Number(value); // Convert to number
}

export const convertIntoPercentage = (vl) => {
    // console.log("convertIntoPercentage -> typeof(vl) -> " + typeof(vl))
    // console.log("convertIntoPercentage -> vl -> " + vl)
    let result = 0;
    if (vl === null || vl === undefined || vl === "" || isNaN(vl)) {
        // return vl
        result = 0.00 //0.00
    } else {
        if (vl.toString().includes(".")) {
            if (vl > 1) {
                result = parseFloat(vl).toFixed(2) //+ "%"
            } else {
                result = parseFloat(vl * 100).toFixed(2) //+ "%"
            }
        } else {
            result = parseFloat(vl).toFixed(2) //+ "%"
        }
    }
    return Math.abs(Number(result));
}

export const prepareSummaryReportData = (tmpLlData) => {
    if (sessionStorage.getItem("user_type") === "vendor" || sessionStorage.getItem("user_type") === "merch reference" || sessionStorage.getItem("user_type") === "read-only") {
        console.log("prepareSummaryReportData -> E-0 -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
        return [];
    }

    if (tmpLlData === null || tmpLlData === undefined || tmpLlData.length === 0) {
        console.log("prepareSummaryReportData -> E-1 -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
        return [];
    }

    if (tmpLlData !== null && tmpLlData !== undefined && tmpLlData.length > 0) {
        console.log("prepareSummaryReportData -> tmpLlData.length -> " + tmpLlData.length)
    }

    const summaryReportSrcDataCols = ["Department", "Category"]
    const summaryReportNumericCols = ["MSRP", "WHLS", "LDP", "DTC Units Total", "Wholesale Units Total", "Brand Units Total", "Scenario LDP", "Total DTC SLS $", "Total WHSL SLS $", "Total Brand SLS $", "DTC Units Total", "DTC Chase Units", "Wholesale Units Total", "Wholesale Chase Units"]

    // let formattedData = tmpLlData.filter((dt) => dt["Style Activity"] && dt["Style Activity"].toUpperCase() === "ACTIVE");

    let formattedData = _.map(tmpLlData, (row) => {
        // Use _.mapValues to iterate over each row and modify the selected columns
        return _.mapValues(row, (value, key) => {
            if (summaryReportSrcDataCols.includes(key) && (value === null || value === undefined)) {
                return ''; // Set null or undefined to blank
            }
            return value; // Keep other values as is
        });
    });

    //convert numeric data and string data to propercase
    summaryReportSrcDataCols.map((col) => {
        formattedData = formattedData.map(item => ({
            ...item,
            [col]: ProperCase(item[col]),
        }));
    });

    summaryReportNumericCols.map((col) => {
        formattedData = formattedData.map(item => ({
            ...item,
            [col]: convertToNumber(item[col]),
        }));
    });

    // console.log("numeric format", formattedData)
    // group by Season Code, Department, Category 
    const groupedData = _.groupBy(formattedData, item => `${item["Season Code"]}_${item["Department"]}_${item["Category"]}`);

    // Group and sum
    const summaryReportData = _.map(groupedData, group => {
        const msrpSum = _.sumBy(group, 'MSRP');
        const ldpSum = _.sumBy(group, 'LDP');
        const retailMarginActual = (msrpSum - ldpSum) / msrpSum

        const whlsSum = _.sumBy(group, 'WHLS');
        const wholesaleMarginActual = whlsSum !== 0 ? (whlsSum - ldpSum) / whlsSum : 0;
        const blendedBrandMarginActual = (retailMarginActual + wholesaleMarginActual) / 2;

        const dtcUnitsTotalSum = _.sumBy(group, 'DTC Units Total');
        const dtcChaseUnitsSum = _.sumBy(group, 'DTC Chase Total');
        const dtcUnitsTotalPlusDtcChaseUnits = dtcUnitsTotalSum + dtcChaseUnitsSum;

        const totalMsrpDollar = msrpSum * dtcUnitsTotalPlusDtcChaseUnits;
        const totalDtcLdp = dtcUnitsTotalPlusDtcChaseUnits + ldpSum;

        const scenarioLdpSum = _.sumBy(group, 'Scenario LDP');
        const retailMarginScenario = msrpSum !== 0 ? (msrpSum - scenarioLdpSum) / msrpSum : 0;
        const wholesaleMarginScenario = whlsSum !== 0 ? (whlsSum - ldpSum) / whlsSum : 0;
        const retailGrossMarginActual = totalMsrpDollar !== 0 ? ((totalMsrpDollar - totalDtcLdp) / totalMsrpDollar) : 0;
        const retailGrossMarginScenario = (msrpSum * dtcUnitsTotalSum) !== 0 ? (msrpSum - scenarioLdpSum) / (msrpSum * dtcUnitsTotalSum) : 0;
        const blendedBrandMarginScenario = (retailMarginScenario + wholesaleMarginScenario) / 2;

        const wholesaleUnitsTotal = _.sumBy(group, 'Wholesale Units Total');
        const wholesaleUnitsTotalPlusWholesaleChaseUnits = (dtcUnitsTotalPlusDtcChaseUnits / 2) * 1.5;
        const totalWhslDollar = whlsSum * wholesaleUnitsTotalPlusWholesaleChaseUnits;
        const totalWhslLdp = wholesaleUnitsTotalPlusWholesaleChaseUnits * ldpSum;
        const wholesaleGrossMarginActual = totalWhslDollar !== 0 ? (totalWhslDollar - totalWhslLdp) / totalWhslDollar : 0;
        const blendedBrandGrossMarginActual = ((retailGrossMarginActual + wholesaleGrossMarginActual) / 2);
        const wholesaleGrossMarginScenario = (whlsSum * wholesaleUnitsTotal) !== 0 ? (whlsSum - ldpSum) / (whlsSum * wholesaleUnitsTotal) : 0;
        const blendedBrandGrossMarginScenario = ((retailGrossMarginScenario + wholesaleGrossMarginScenario) / 2);
        const retailMarginVariance = (retailMarginScenario - retailMarginActual);
        const wholesaleMarginVariance = (wholesaleMarginScenario - wholesaleMarginActual);
        const blendedBrandMarginVariance = (blendedBrandMarginScenario - blendedBrandMarginActual);
        const retailGrossMarginVariance = (retailGrossMarginScenario - retailGrossMarginActual);
        const wholesaleGrossMarginVariance = (wholesaleGrossMarginScenario - wholesaleGrossMarginActual);
        const blendedBrandGrossMarginVariance = (blendedBrandGrossMarginScenario - blendedBrandGrossMarginActual);

        const brandUnitsTotal = _.sumBy(group, 'Brand Units Total');
        const totalDtcSlsSum = _.sumBy(group, 'Total DTC SLS $');
        const totalWhslSlsSum = _.sumBy(group, 'Total WHSL SLS $');
        const totalBrandSlsSum = _.sumBy(group, 'Total Brand SLS $');

        return {
            "Season Code": group[0]["Season Code"],
            "Department": group[0]["Department"],
            "Category": group[0]["Category"],
            "Count of Style": group.length,
            "Retail Margin (Actual)": convertIntoPercentage(retailMarginActual),
            "Wholesale Margin (Actual)": convertIntoPercentage(wholesaleMarginActual),
            "Blended Brand Margin (Actual)": convertIntoPercentage(blendedBrandMarginActual),
            "Retail Gross Margin (Actual)": convertIntoPercentage(retailGrossMarginActual),
            "Wholesale Gross Margin (Actual)": convertIntoPercentage(wholesaleGrossMarginActual),
            "Blended Brand Gross Margin (Actual)": convertIntoPercentage(blendedBrandGrossMarginActual),
            "Retail Margin (Scenario)": convertIntoPercentage(retailMarginScenario),
            "Wholesale Margin (Scenario)": convertIntoPercentage(wholesaleMarginScenario),
            "Blended Brand Margin (Scenario)": convertIntoPercentage(blendedBrandMarginScenario),
            "Retail Gross Margin (Scenario)": convertIntoPercentage(retailGrossMarginScenario),
            "Wholesale Gross Margin (Scenario)": convertIntoPercentage(wholesaleGrossMarginScenario),
            "Blended Brand Gross Margin (Scenario)": convertIntoPercentage(blendedBrandGrossMarginScenario),
            "Retail Margin Variance": convertIntoPercentage(retailMarginVariance),
            "Wholesale Margin Variance": convertIntoPercentage(wholesaleMarginVariance),
            "Blended Brand Margin Variance": convertIntoPercentage(blendedBrandMarginVariance),
            "Retail Gross Margin Variance": convertIntoPercentage(retailGrossMarginVariance),
            "Wholesale Gross Margin Variance": convertIntoPercentage(wholesaleGrossMarginVariance),
            "Blended Brand Gross Margin Variance": convertIntoPercentage(blendedBrandGrossMarginVariance),
            "DTC Units Total": parseInt(dtcUnitsTotalSum),
            "Wholesale Units Total": parseInt(wholesaleUnitsTotal),
            "Brand Units Total": parseInt(brandUnitsTotal),
            "Total DTC SLS $": parseFloat(totalDtcSlsSum),
            "Total WHSL SLS $": parseFloat(totalWhslSlsSum),
            "Total Brand SLS $": parseFloat(totalBrandSlsSum)
        }
    });

    //sort data
    const finalResult = _.sortBy(summaryReportData, ["Season Code", "Department", "Category"]);
    // Calculate grand totals for multiple columns
    const grandTotals = {
        "Season Code": "",
        "Department": "",
        "Category": "Grand Total",
        "Count of Style": _.sumBy(summaryReportData, 'Count of Style'),
        'Retail Margin (Actual)': convertIntoPercentage(_.meanBy(summaryReportData, 'Retail Margin (Actual)')),
        'Wholesale Margin (Actual)': convertIntoPercentage(_.meanBy(summaryReportData, 'Wholesale Margin (Actual)')),
        'Blended Brand Margin (Actual)': convertIntoPercentage(_.meanBy(summaryReportData, 'Blended Brand Margin (Actual)')),
        'Retail Gross Margin (Actual)': convertIntoPercentage(_.meanBy(summaryReportData, 'Retail Gross Margin (Actual)')),
        'Wholesale Gross Margin (Actual)': convertIntoPercentage(_.meanBy(summaryReportData, 'Wholesale Gross Margin (Actual)')),
        'Blended Brand Gross Margin (Actual)': convertIntoPercentage(_.meanBy(summaryReportData, 'Blended Brand Gross Margin (Actual)')),
        'Retail Margin (Scenario)': convertIntoPercentage(_.meanBy(summaryReportData, 'Retail Margin (Scenario)')),
        'Wholesale Margin (Scenario)': convertIntoPercentage(_.meanBy(summaryReportData, 'Wholesale Margin (Scenario)')),
        'Blended Brand Margin (Scenario)': convertIntoPercentage(_.meanBy(summaryReportData, 'Blended Brand Margin (Scenario)')),
        'Retail Gross Margin (Scenario)': convertIntoPercentage(_.meanBy(summaryReportData, 'Retail Gross Margin (Scenario)')),
        'Wholesale Gross Margin (Scenario)': convertIntoPercentage(_.meanBy(summaryReportData, 'Wholesale Gross Margin (Scenario)')),
        'Blended Brand Gross Margin (Scenario)': convertIntoPercentage(_.meanBy(summaryReportData, 'Blended Brand Gross Margin (Scenario)')),
        'Retail Margin Variance': convertIntoPercentage(_.meanBy(summaryReportData, 'Retail Margin Variance')),
        'Wholesale Margin Variance': convertIntoPercentage(_.meanBy(summaryReportData, 'Wholesale Margin Variance')),
        'Blended Brand Margin Variance': convertIntoPercentage(_.meanBy(summaryReportData, 'Blended Brand Margin Variance')),
        'Retail Gross Margin Variance': convertIntoPercentage(_.meanBy(summaryReportData, 'Retail Gross Margin Variance')),
        'Wholesale Gross Margin Variance': convertIntoPercentage(_.meanBy(summaryReportData, 'Wholesale Gross Margin Variance')),
        'Blended Brand Gross Margin Variance': convertIntoPercentage(_.meanBy(summaryReportData, 'Blended Brand Gross Margin Variance')),
        'DTC Units Total': _.sumBy(summaryReportData, 'DTC Units Total'),
        'Wholesale Units Total': _.sumBy(summaryReportData, 'Wholesale Units Total'),
        'Brand Units Total': _.sumBy(summaryReportData, 'Brand Units Total'),
        'Total DTC SLS $': _.sumBy(summaryReportData, 'Total DTC SLS $'),
        'Total WHSL SLS $': _.sumBy(summaryReportData, 'Total WHSL SLS $'),
        'Total Brand SLS $': _.sumBy(summaryReportData, 'Total Brand SLS $'),
    };

    finalResult.push(grandTotals);
    // console.log("summaryReportData", finalResult);
    return finalResult;
}