import { Grid, List, ListItemButton, ListItemText } from '@mui/material';

const ListHistoricData = ({ historicData, setVal }) => {

    return (
        <List sx={{ height: "200px", overflow: "hidden", overflowY: "auto" }}>
            <Grid container spacing={1}>
                {historicData.map((data) => (
                    <Grid item xs={4} md={3}>
                        <ListItemButton
                            key={data}
                            onClick={() => setVal(data)}
                            sx={{
                                mb: 1,
                                padding: '4px 8px', // Adjusted padding for smaller button size
                                borderRadius: '2px',
                                backgroundColor: '#ffffff',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                            }}
                        >
                            <ListItemText
                                primary={data}
                                primaryTypographyProps={{
                                    variant: 'body2', // Reduced font size for list items
                                    fontWeight: 'medium',
                                    color: '#333',
                                    fontSize: '10px',
                                    textAlign: "center"
                                }}
                            />
                        </ListItemButton>
                    </Grid>
                ))}
            </Grid>
        </List>
    )
}

export default ListHistoricData;