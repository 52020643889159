import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { xlXlookup } from "layouts/linelist/XL_Calculation";
import {
  CH1,
  CH2,
  ExcelDateToJsDate,
  PipeDel,
  checkHeihlight,
  findColumnPosOnName,
  getColumnDefByTblName,
  getColumnDropDownValue,
  getDatePicker,
  isValueBlank,
  toastMessage,
  formatDateToMmDdYyyyHhMmSsAmPm,
} from "layouts/linelist/XL_Utils";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { applicationTitle } from "utils/app.props";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { getStateDataByTblName } from "layouts/linelist/getChangesComman";
import { setStateDataByTblName } from "layouts/linelist/getChangesComman";
const maxBulkImportLimit = 1000;
const sizesName = ["2T", "3T", "4T", "5", "6", "8", "10", "OS", "XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL", "XS/S", "S/M", "M/L", "L/XL", "MT", "LT", "XLT", "XXLT", "00_", "0", "2", "4", "6_", "8_", "10_", "12", "14", "16", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "38", "40", "42", "30T", "32T", "34T", "36T", "38T", "40T", "28WX30L", "29WX30L", "30WX30L", "31WX30L", "32WX30L", "33WX30L", "34WX30L", "35WX30L", "36WX30L", "38WX30L", "40WX30L", "42WX30L", "28WX32L", "29WX32L", "30WX32L", "31WX32L", "32WX32L", "33WX32L", "34WX32L", "35WX32L", "36WX32L", "38WX32L", "40WX32L", "42WX32L", "28WX34L", "29WX34L", "30WX34L", "31WX34L", "32WX34L", "33WX34L", "34WX34L", "35WX34L", "36WX34L", "38WX34L", "40WX34L", "42WX34L", "1XL", "2XL", "3XL", "4XL", "5XL", "1XLT", "2XLT", "3XLT", "4XLT", "40W36L", "42W32L", "42W34L", "44W30L", "44W32L", "44W34L", "46W30L", "46W32L", "48W30L", "48W32L", "50W30L", "52W30L", "36_", "38_", "40_", "42_", "44_", "46_", "48_", "50_"]
function ImportData({
  activeTabGridApi,
  req_tbl,
  tabNm,
  importDataFromSheet,
  onImportDataCloseClick,
}) {
  const [colDef, setColDef] = useState([]);
  const [srcData, setSrcData] = useState([]);
  const gridApi = useRef({});
  const gridColumnApi = useRef({});
  const dataTransferRules = useSelector((s) => s.dataTransferRules || []);
  const FbFtyDatabaseData = useSelector((s) => s.FbFtyDatabaseData || []);
  const [SnackBarNoti, setSnackBarNoti] = useState(false);
  const [SnackBarNotiMsg, setSnackBarNotiMsg] = useState("");
  const [SnackBarNotiColor, setSnackBarNotiColor] = useState("info");
  const coloredCells = useRef([]);
  const openSnackBarNoti = () => setSnackBarNoti(true);
  const closeSnackBarNoti = () => setSnackBarNoti(false);
  const msgArr = useRef([]);
  const getCellStyle = (params, col) => {
    let bgColor = "";
    let ky = req_tbl + "|" + col.headerName.trim() + "|" + params.node.data.row_id;
    let obj = checkHeihlight(ky, coloredCells.current);
    if (obj.status) {
      if (obj.colorCode === 2) {
        bgColor = "#D5A7F2";
      }
    } else {
      if (col.type === "dropdown") {
        bgColor = "#FDFCEC";
      }
    }
    return { backgroundColor: bgColor };
  };
  const getValueGetter = (params, col) => {
    if (col.headerName.trim() === "Total") {
      let sumOfSizes = 0;
      for (let i = 0; i < sizesName.length; i++) {
        let oneSzVl = 0;
        if (
          params.node.data[sizesName[i]] === null ||
          params.node.data[sizesName[i]] === undefined ||
          params.node.data[sizesName[i]].trim() === ""
        ) {
          oneSzVl = 0;
        } else {
          oneSzVl = parseFloat(params.node.data[sizesName[i]]);
        }
        sumOfSizes = sumOfSizes + oneSzVl;
      }
      return sumOfSizes;
    } else {
      return params.node.data[col.field];
    }
  };
  useEffect(() => {
    const tmpColDef = getColumnDefByTblName(importDataFromSheet);
    if (tmpColDef !== null && tmpColDef !== undefined && tmpColDef.length > 0) {
      let cd = tmpColDef;
      if (cd && cd.length > 0) {
        cd = cd.map((col) => {
          if (importDataFromSheet === "Sizing_Import") {
            col = {
              ...col,
              cellStyle: (params) => {
                return getCellStyle(params, col);
              },
              valueGetter: (params) => {
                return getValueGetter(params, col);
              },
            };
          } else {
            col = {
              ...col,
              cellStyle: (params) => {
                return getCellStyle(params, col);
              },
            };
          }
          // 18-Sep-2024
          if (importDataFromSheet === "Merch_Assortment_Sandbox_Import" && col.headerName === "Upload To Linelist Check") {
            col = {
              ...col,
              hide: true
            };
            // console.log("col -> ", col);
          }
          // 18-Sep-2024
          return col;
        });
      }
      setColDef(cd);
      let tmpData = [];
      let dObj = new Object();
      for (let cnt = 0; cnt <= (maxBulkImportLimit - 1); cnt++) {
        for (let i = 0; i < cd.length; i++) {
          dObj["" + cd[i]["field"] + ""] = null;
        }
        dObj["" + "row_id" + ""] = cnt;
        tmpData.push(dObj);
        dObj = new Object();
      }
      setSrcData(tmpData);
    }
  }, []);
  const onGridReady = (params) => {
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;
  };
  const postSetArea = (dt) => {
    if (importDataFromSheet === "Linelist_Import") {
      if (sessionStorage.getItem("user_type").toLowerCase() === "Merch".toLowerCase()) {
        dt = clearDataOfColumns(dt);
      }
      dt = populateDataBasedOnFactoryInLinelistMerge(dt);
      dt = populateColorCodeForLinelist(dt)
    } else if (importDataFromSheet === "PD_Initial_Costing_Import") {
    } else if (importDataFromSheet === "Sizing_Import") {
    } else if (importDataFromSheet === "Comp_APS_Master_StoreEco_Import") {
    }
    return dt;
  };
  const checkSetArea = () => {
    let gridRowData = [];
    gridApi.current.forEachNode((node, index) => {
      let isBlank = true;
      gridApi.current.getAllDisplayedColumns().map(function (col) {
        const cn = col.colDef.headerName;
        let tmpVl = node.data[cn];
        if (tmpVl !== null && tmpVl !== undefined && tmpVl.toString().trim() !== "") {
          isBlank = false;
        }
      });
      if (isBlank === false) {
        node.data["row_id"] = index;
        gridRowData.push(node.data);
      }
    });
    if (gridRowData === null || gridRowData === undefined || gridRowData.length === 0) {
      setSnackBarNotiMsg("Please input some data and click Set Area button.");
      setSnackBarNotiColor("info");
      openSnackBarNoti();
      return [];
    } else {
      gridRowData = postSetArea(gridRowData);
      setSrcData(gridRowData);
      return gridRowData;
    }
  };
  const populateDataBasedOnFactoryInLinelistMerge = (dt) => {
    for (let i = 0; i < dt.length; i++) {
      let deptVal = dt[i]["Department"];
      deptVal =
        deptVal === null || deptVal === undefined || deptVal.toString().trim() === ""
          ? ""
          : deptVal.toLowerCase();
      dt[i]["Brand"] =
        deptVal === "mens" || deptVal === "womens" || deptVal === "kids" ? "Faherty Brand" : "";
      let rsVl = xlXlookup(dt[i]["Factory"], FbFtyDatabaseData, "Factory", "Corporate/Agent");
      rsVl = rsVl === null || rsVl === undefined || rsVl.toString().trim() === "" ? "" : rsVl;
      dt[i]["Corporate/Agent"] = rsVl;
      rsVl = null;
      rsVl = xlXlookup(dt[i]["Factory"], FbFtyDatabaseData, "Factory", "Factory Country");
      dt[i]["COO"] = rsVl;
      rsVl = null;
      rsVl = xlXlookup(dt[i]["Factory"], FbFtyDatabaseData, "Factory", "FOB/DDP/LDP");
      dt[i]["Shipping Terms (FOB/DDP/LDP)"] = rsVl;
      rsVl = null;
    }
    return dt;
  };
  const populateColorCodeForLinelist = (dt) => {
    let ccVal = "";
    for (let i = 0; i < dt.length; i++) {
      ccVal = dt[i]["Color Code"];
      if (
        ccVal !== null &&
        ccVal !== undefined &&
        ccVal.toString().trim() !== "" &&
        ccVal.toString().toLowerCase().includes("tbd")
      ) {
        dt[i]["Color Code"] = "";
      }
      ccVal = null;
    }
    let dtPkMp = new Map();
    let pkCls = "";
    for (let i = 0; i < dt.length; i++) {
      pkCls = (dt[i]["Year"] + dt[i]["Season Code"] + dt[i]["Department"] + dt[i]["Style"]);
      if (dtPkMp.has(pkCls) === false) {
          dtPkMp.set(pkCls, dt[i]["Color Code"]);
      }
      pkCls = "";
    }
    let pkWiseVlMp = new Map();
    let cnt = 0;
    for (let i = 0; i < dt.length; i++) {
      pkCls = (dt[i]["Year"] + dt[i]["Season Code"] + dt[i]["Department"] + dt[i]["Style"]);
      if (dtPkMp.has(pkCls)) {
        ccVal = dt[i]["Color Code"];
        if (pkWiseVlMp.has(pkCls) === false) {
            if (ccVal === null || ccVal === undefined || ccVal.toString().trim() === "") {
              cnt = 1;
              dt[i]["Color Code"] = ("TBD" + cnt);
              pkWiseVlMp.set(pkCls, cnt);
            }
        } else {
          cnt = pkWiseVlMp.get(pkCls);
          if (ccVal === null || ccVal === undefined || ccVal.toString().trim() === "") {
            cnt = cnt + 1;
            dt[i]["Color Code"] = ("TBD" + cnt);
            pkWiseVlMp.set(pkCls, cnt);
          }
        }
        ccVal = null;
      }
    }
    return dt;
  };
  const clearDataOfColumns = (dt) => {
    const cols = ["Costing Status", "Factory"];
    for (let i = 0; i < dt.length; i++) {
      for (let j = 0; j < cols.length; j++) {
        dt[i][cols[j]] = "";
      }
    }
    return dt;
  };
  const onSetAreaClick = () => {
    let properAreaData = checkSetArea();
    coloredCells.current = new Array();
  };
  const onAddRowClick = () => {
    let oneClVl = new Object();
    gridApi.current.getColumnDefs().forEach((od) => {
      // oneClVl["" + od.field + ""] = "";
      oneClVl["" + od.field + ""] = null;
    });
    let lstVal = [];
    lstVal.push(oneClVl);
    const stateData = srcData;
    if (stateData) {
      let newData = [oneClVl, ...stateData];
      setSrcData(newData);
    }
  };
  const onDeleteRowClick = () => {
    let focusedCell = gridApi.current.getFocusedCell();
    if (focusedCell === null || focusedCell === undefined) {
      toastMessage("Please first click on cell in tab data.");
      return;
    }
    let oneRowData;
    gridApi.current.forEachNode((rowNode, index) => {
      if (rowNode.rowIndex === focusedCell.rowIndex) oneRowData = rowNode;
    });
    const tmpMyData = srcData;
    if (tmpMyData) {
      let myData = [];
      myData = tmpMyData.filter((item) => item !== oneRowData.data);
      setSrcData(myData);
    }
  };
  const GetDtRule = (vl) => {
    if (
      dataTransferRules === null ||
      dataTransferRules === undefined ||
      dataTransferRules.length === 0
    ) {
      return {};
    } else {
      for (let i = 0; i < dataTransferRules.length; i++) {
        if (
          dataTransferRules[i]["origin_data_name"] === vl &&
          dataTransferRules[i]["group_name"].toLowerCase() ===
            sessionStorage.getItem("user_type").toLowerCase()
        ) {
          return dataTransferRules[i];
        }
      }
    }
  };
  const getOriginDataPKsWithIndex = (OrgPKCols, dt) => {
    let srcPkValMap = new Map();
    for (let n = 0; n < dt.length; n++) {
      let oneRowPkLst = new Array();
      for (let i = 0; i < OrgPKCols.length; i++) {
        oneRowPkLst.push((dt[n][OrgPKCols[i]] === null || dt[n][OrgPKCols[i]] === undefined || dt[n][OrgPKCols[i]].toString().trim() === "") ? "" : dt[n][OrgPKCols[i]])
      }
      let onePkVl = oneRowPkLst.join(PipeDel).toLowerCase();
      srcPkValMap.set(onePkVl, n);
      onePkVl = null;
      oneRowPkLst = new Array();
    }
    // gridApi.current.forEachNode((nd, index) => {
    //     let oneRowPkLst = new Array()
    //     for (let i = 0; i < OrgPKCols.length; i++) {
    //         oneRowPkLst.push((nd.data[OrgPKCols[i]] === null || nd.data[OrgPKCols[i]] === undefined || nd.data[OrgPKCols[i]].toString().trim() === "") ? "" : nd.data[OrgPKCols[i]])
    //     }
    //     let onePkVl = oneRowPkLst.join(PipeDel).toLowerCase()
    //     srcPkValMap.set(onePkVl, index)
    //     onePkVl = null
    //     oneRowPkLst = new Array()
    // })
    return srcPkValMap;
  };
  const getDestDataPKsWithIndex = (DestPKCols) => {
    let destPkValMap = new Map();
    activeTabGridApi.forEachNode((nd, index) => {
      let oneRowPkLst = new Array();
      for (let i = 0; i < DestPKCols.length; i++) {
        oneRowPkLst.push((nd.data[DestPKCols[i]] === null || nd.data[DestPKCols[i]] === undefined || nd.data[DestPKCols[i]].toString().trim() === "") ? "" : nd.data[DestPKCols[i]])
      }
      let onePkVl = oneRowPkLst.join(PipeDel).toLowerCase();
      destPkValMap.set(onePkVl, index);
      onePkVl = null;
      oneRowPkLst = new Array();
    });
    return destPkValMap;
  };
  const verifyOriginDataForDuplicatePKs = (OrgPKCols, dt) => {
    let isDuplicateFound = false;
    let dupSrcPkValIdxMap = new Map();
    // gridApi.current.forEachNode((nd, index) => {
    for (let n = 0; n < dt.length; n++) {
      let oneRowPkLst = new Array();
      for (let i = 0; i < OrgPKCols.length; i++) {
        // oneRowPkLst.push((nd.data[OrgPKCols[i]] === null || nd.data[OrgPKCols[i]] === undefined || nd.data[OrgPKCols[i]].toString().trim() === "") ? "" : nd.data[OrgPKCols[i]])
        oneRowPkLst.push((dt[n][OrgPKCols[i]] === null || dt[n][OrgPKCols[i]] === undefined || dt[n][OrgPKCols[i]].toString().trim() === "") ? "" : dt[n][OrgPKCols[i]])
      }
      let onePkVl = oneRowPkLst.join(PipeDel).toLowerCase();
      // srcPkValMap.set(index, onePkVl)
      if (dupSrcPkValIdxMap.has(onePkVl) === false) {
        // dupSrcPkValIdxMap.set(onePkVl, [index])
        dupSrcPkValIdxMap.set(onePkVl, [n]);
      } else {
        isDuplicateFound = true;
        // let prevIdx = dupSrcPkValIdxMap.get(onePkVl)
        // dupSrcPkValIdxMap.set(onePkVl, prevIdx.concat([index]))
        // prevIdx = new Array()
        // dupSrcPkValIdxMap.set(onePkVl, dupSrcPkValIdxMap.get(onePkVl).concat([index]))
        dupSrcPkValIdxMap.set(onePkVl, dupSrcPkValIdxMap.get(onePkVl).concat([n]));
      }
      onePkVl = null;
      oneRowPkLst = new Array();
    }
    // })
    if (isDuplicateFound) {
      return dupSrcPkValIdxMap;
    } else {
      return {};
    }
  };
  const getColValFromDtRule = (oneDtRule, colNm) => {
    let retVal = false;
    if (oneDtRule[colNm] === null || oneDtRule[colNm] === undefined || oneDtRule[colNm].toString().trim() === "" || oneDtRule[colNm].toString().trim().toLowerCase() === "NO".toLowerCase()) {
      retVal = false;
    } else {
      if (oneDtRule[colNm].toString().trim().toLowerCase() === "YES".toLowerCase()) {
        retVal = true;
      } else {
        retVal = false;
      }
    }
    return retVal;
  };
  const validateTabData = (event, dataToValidate) => {
    const tmpColDef = getColumnDefByTblName(importDataFromSheet);
    const tmpValidation = _.map(tmpColDef, ({ field, validation, editable, column_data_source, is_formula_col }) => ({ field, validation, editable, column_data_source, is_formula_col }));
    const validations = _.filter(tmpValidation, obj => {
      return (obj.editable === true || obj.is_formula_col === true) && (obj.validation && obj.validation.trim().length > 0);
    });
    if (validations === null || validations === undefined || validations.length === 0) {
      return ({ "status": true, "msg": "" })
    }
    let validationStatus = true;
    let message = "";
    if (event) {
      const { node } = event;
      const column = event.colDef.field;
      const cellVal = node.data[column];
      const validationRules = _.filter(validations, obj => {
        return obj.field === column
      });
      if (validationRules.length === 0) return ({ "status": true, "msg": "" });
      const validationRule = validationRules[0];
      const checkValidation = validationRule.validation ? validationRule.validation.split("|") : [];
      if (checkValidation.length > 0) {
        checkValidation.some((validate) => {
          if (validate.toUpperCase() === "MANDATORY") {
            if (!cellVal || cellVal === "") {
              validationStatus = false;
              message = "Please enter data at highlighted cells. Mandatory columns should not be blank.";
              coloredCells.current.push({ [req_tbl + "|" + column + "|" + node.data.row_id]: 2 });
              return true;
            }
            return false;
          } else if (cellVal && cellVal !== "") {
            switch (validate.toUpperCase()) {
              case "DROP_DOWN": {
                const dropdownValues = getColumnDropDownValue(validationRule.column_data_source);
                if (!dropdownValues.includes(cellVal)) {
                  validationStatus = false;
                  message = "Please select value from drop-down list at highlighted cells.";
                  coloredCells.current.push({ [req_tbl + "|" + column + "|" + node.data.row_id]: 2 });
                  return true;
                }
                return false;
              }
              case "INTEGER": {
                const isInteger = /^-?\d+$/.test(cellVal);
                if (!isInteger) {
                  validationStatus = false;
                  message = "Please enter integer in this column.";
                  coloredCells.current.push({ [req_tbl + "|" + column + "|" + node.data.row_id]: 2 });
                  return true;
                }
                return false;
              }
              case "NUMERIC": {
                const isNumeric = /^-?\d*\.?\d+$/.test(cellVal);
                if (!isNumeric) {
                  validationStatus = false;
                  message = "Please enter numeric value in this column.";
                  coloredCells.current.push({ [req_tbl + "|" + column + "|" + node.data.row_id]: 2 });
                  return true;
                }
                return false;
              }
              case "DATE": {
                // const dateRegex = /^(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])-(\d{2})$/;
                const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(\d{4})$/;
                const isDate = dateRegex.test(cellVal);
                if (!isDate) {
                  validationStatus = false;
                  message = "Please enter proper date value in this column.";
                  coloredCells.current.push({ [req_tbl + "|" + column + "|" + node.data.row_id]: 2 });
                  return true;
                }
                return false;
              }
              case "EMAIL_ADDRESS": {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                const isEmail = emailRegex.test(cellVal);
                if (!isEmail) {
                  validationStatus = false;
                  message = "Please enter valid email.";
                  coloredCells.current.push({ [req_tbl + "|" + column + "|" + node.data.row_id]: 2 });
                  return true;
                }
                return false;
              }
              case "NON_KEY_BOARD_CHARACTERS": {
                const nonKeyboardRegex = /^[ABCDEFGHIJKLMNOPQRSTUVWXYZa-z 0123456789 \.,\?'""!@#\$%\^&\\n\t\r\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]/;
                const isNonKeyBoard = nonKeyboardRegex.test(cellVal);
                if (!isNonKeyBoard) {
                  validationStatus = false;
                  message = "Please enter proper data in this column.";
                  coloredCells.current.push({ [req_tbl + "|" + column + "|" + node.data.row_id]: 2 });
                  return true;
                }
                return false;
              }
              case "ALPHA_NUMERIC": {
                const alphanumericRegex = /^[a-zA-Z0-9]+$/;
                const isAlphaNumeric = alphanumericRegex.test(cellVal);
                if (!isAlphaNumeric) {
                  validationStatus = false;
                  message = "Space and Special Characters not allowed.";
                  coloredCells.current.push({ [req_tbl + "|" + column + "|" + node.data.row_id]: 2 });
                  return true;
                }
                return false;
              }
              case "STRING": {
                const stringRegex = /^[a-zA-Z ]+$/;
                const isString = stringRegex.test(cellVal);
                if (!isString) {
                  validationStatus = false;
                  message = "Space and Special Characters not allowed.";
                  coloredCells.current.push({ [req_tbl + "|" + column + "|" + node.data.row_id]: 2 });
                  return true;
                }
                return false;
              }
              case "DATA_CRITERIA": {
                const asRequiredDownloadQuery = sessionStorage.getItem("asRequiredDownloadQuery")
                if (asRequiredDownloadQuery && asRequiredDownloadQuery !== "" && asRequiredDownloadQuery.includes(column)) {
                  if (!asRequiredDownloadQuery.includes(cellVal)) {
                    validationStatus = false;
                    message = "Please enter the data as per your downloaded criteria at highlighted cells.";
                    coloredCells.current.push({ [req_tbl + "|" + column + "|" + node.data.row_id]: 2 });
                    return true;
                  }
                }
                return false;
              }
              default: {
                validationStatus = true;
                message = "";
                return false;
              }
            }
          }
        })
      } else if (cellVal && cellVal !== "") {
        // If no validation define then check for non key board chacter
        const nonKeyboardRegex = /^[ABCDEFGHIJKLMNOPQRSTUVWXYZa-z 0123456789 \.,\?'""!@#\$%\^&\\n\t\r\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]/;
        const isNonKeyBoard = nonKeyboardRegex.test(cellVal);
        if (!isNonKeyBoard) {
          validationStatus = false;
          message = "Please enter proper data in this column.";
          coloredCells.current.push({ [req_tbl + "|" + column + "|" + node.data.row_id]: 2 });
        }
      }
    } else {
      const BreakException = {}
      try {
        for (let i = 0; i < tmpColDef.length; i++) {
          const validationRules = _.filter(validations, obj => {
            return obj.field === tmpColDef[i].field
          });
          if (validationRules.length === 0) return ({ "status": true, "msg": "" });
          const validationRule = validationRules[0];
          if (validationRule.validation && validationRule.validation.includes("CONTIGUOUS_CELLS_VALUE")) { }
          if (validationRule.validation && validationRule.validation.includes("DUP")) {
            const duplicateVal = [];
            validations.map((val) => {
              if (val && val.validation) {
                const arr = val.validation.split("|");
                const dupArr = _.filter(arr, (val) => val.includes("DUP"));
                dupArr.map((dt) => duplicateVal.push({ column: val.field, validation: dt }))
              }
            })
            const groupVal = _.groupBy(duplicateVal, "validation");
            Object.entries(groupVal).map(([key, value]) => {
              const columns = _.map(value, "column");
              // Function to generate a unique key for each object based on specified keys
              const generateKey = obj => columns.map(key => obj[key]).join("|");
              // Group objects by the generated key
              const grouped = _.groupBy(dataToValidate, generateKey);
              // Filter out groups that have more than one item (i.e., duplicates)
              const duplicates = _.filter(grouped, group => group.length > 1);
              if (duplicates.length > 0) {
                validationStatus = false;
                message = "Duplicate value found. Please correct it.";
                duplicates[0].map((dp) => {
                  Object.entries(dp).map(([key, value]) => {
                    if (columns.includes(key)) {
                      const key1 = req_tbl + "|" + key + "|" + dp.row_id;
                      coloredCells.current.push({ [key1]: 2 });
                    }
                  })
                })
                throw BreakException;
              }
            })
          }
          if (validationStatus && validationRule.validation && validationRule.validation.includes("MANDATORY")) {
            const mandatory = []
            validations.map((val) => {
              if (val && val.validation) {
                const arr = val.validation.split("|");
                const dupArr = _.filter(arr, (val) => val.includes("MANDATORY"));
                dupArr.map((dt) => mandatory.push({ column: val.field, validation: dt }))
              }
            })
            // const colValues = _.map(dataToValidate, tmpColDef[i].field);
            // const rowIds = _.map(dataToValidate, "row_id");
            // 18-Sep-2024 : To sync logic with Merch_Assortment_Sandbox template
            // console.log("dataToValidate -> ", dataToValidate);
            let filterValues = [];
            if (req_tbl === "xl_faherty_merch_assortment_sandbox") {
              filterValues = dataToValidate.filter((dt) => (dt["Upload To Linelist Check"] && dt["Upload To Linelist Check"].toLowerCase()) === 'Yes'.toLowerCase());
            } else {
              filterValues = dataToValidate;
            }
            // console.log("filterValues -> ", filterValues);
            // const colValues = _.map(filterValues, tmpColDef[i].field);
            // const rowIds = _.map(filterValues, "row_id");
            // 18-Sep-2024 : To sync logic with Merch_Assortment_Sandbox template
            /*
            const blankIndices = colValues
              .map((value, index) => (isValueBlank(value) ? index : null))
              .filter(index => index !== null);
            if (blankIndices && blankIndices.length > 0) {
              validationStatus = false;
              message = "Please enter data at highlighted cells. Mandatory columns should not be blank.";
              blankIndices.map((index) => {
                const key2 = req_tbl + "|" + tmpColDef[i].field + "|" + rowIds[index];
                coloredCells.current.push({ [key2]: 2 });
              })
              throw BreakException;
            }
            */

            const columnsForMandatory = _.filter(validations, obj => {
              return obj.validation.includes("MANDATORY")
            });
            // console.log("columnsForMandatory -> ", columnsForMandatory);
            columnsForMandatory.map((dt) => {
              const col = dt.field;
              const colValues = _.map(filterValues, col);
              const rowIds = _.map(filterValues, "row_id");
              const blankIndices = colValues
                .map((value, index) => (isValueBlank(value) ? index : null))
                .filter(index => index !== null);
              if (blankIndices && blankIndices.length > 0) {
                validationStatus = false;
                message = "Please enter data at highlighted cells. Mandatory columns should not be blank.";
                blankIndices.map((index) => {
                  const key2 = req_tbl + "|" + col + "|" + rowIds[index];
                  coloredCells.current.push({ [key2]: 2 });
                })
              }
            });
            if (validationStatus === false) {
              throw BreakException;
            }
          }
        }
      } catch (error) {
        if (error !== BreakException) {
          validationStatus = false;
          console.log("ImportData -> validateTabData -> error -> ", error);
          message = error.message;
        }
      }
    }
    return ({ "status": validationStatus, "msg": message });
  };
  const preValidationForSizingImport = (biData) => {
    let yesMatched = false;
    let szGridData = new Array();
    activeTabGridApi.forEachNode(nd => szGridData.push(nd.data));
    // console.log("szGridData -> ", szGridData)
    // const szPkMp = szGridData.map(rw => `${rw["Year"]}|${rw["Season Code"]}|${rw["Department"]}|${rw["SKU"]}|${rw["Location"]}|${rw["Sales Tier"]}`);
    const szPkMp = new Map();
    szGridData.forEach(rw => {
      const k = `${rw["Year"]}|${rw["Season Code"]}|${rw["Department"]}|${rw["SKU"]}|${rw["Location"]}|${rw["Sales Tier"]}`;
      szPkMp.set(k.toLowerCase(), k.toLowerCase());
    });
    // console.log("szPkMp -> ", szPkMp);

    let error1 = 0, error2 = 0, error3 = 0;
    let lstOfRowIdE1 = "", lstOfRowIdE2 = "", lstOfRowIdE3 = "";
    let iPK = ""
    let siSalesTierVal = ""
    let siFromSalesTierVal = ""
    for (let i = 0; i < biData.length; i++) {
      iPK = "", siSalesTierVal = "", siFromSalesTierVal = ""
      siSalesTierVal = biData[i]["Sales Tier"]
      siFromSalesTierVal = biData[i]["From Sales Tier"]
      if (siSalesTierVal && siSalesTierVal.trim() !== "" && siFromSalesTierVal && siFromSalesTierVal.trim() !== "") {
        if (siSalesTierVal.toLowerCase() === siFromSalesTierVal.toLowerCase()) {
          error1 = 1
          const ky1 = (req_tbl + "|" + "Sales Tier" + "|" + biData[i]["row_id"]);
          coloredCells.current.push({ [ky1]: 2 });
          const ky2 = (req_tbl + "|" + "From Sales Tier" + "|" + biData[i]["row_id"]);
          coloredCells.current.push({ [ky2]: 2 });
          if (lstOfRowIdE1 === null || lstOfRowIdE1 === undefined || lstOfRowIdE1.trim() === "") {
            lstOfRowIdE1 = (i + 2 - 1).toString()
          } else {
            lstOfRowIdE1 = (lstOfRowIdE1 + "," + (i + 2 - 1).toString())
          }
        }
      }

      iPK = (biData[i]["Year"] + "|" + biData[i]["Season Code"] + "|" + biData[i]["Department"] + "|" + biData[i]["SKU"] + "|" + biData[i]["Location"] + "|" + biData[i]["From Sales Tier"]).toLowerCase()
      // console.log("2 -> iPK -> ", iPK);
      if (!szPkMp.has(iPK)) {
        error2 = 1
        const ky1 = (req_tbl + "|" + "From Sales Tier" + "|" + biData[i]["row_id"]);
        coloredCells.current.push({ [ky1]: 2 });
        if (lstOfRowIdE2 === null || lstOfRowIdE2 === undefined || lstOfRowIdE2.trim() === "") {
          lstOfRowIdE2 = (i + 2 - 1).toString()
        } else {
          lstOfRowIdE2 = (lstOfRowIdE2 + "," + (i + 2 - 1).toString())
        }
      }
      iPK = ""

      iPK = (biData[i]["Year"] + "|" + biData[i]["Season Code"] + "|" + biData[i]["Department"] + "|" + biData[i]["SKU"] + "|" + biData[i]["Location"] + "|" + biData[i]["Sales Tier"]).toLowerCase()
      // console.log("3 -> iPK -> ", iPK);
      if (szPkMp.has(iPK)) {
        error3 = 1
        const ky1 = (req_tbl + "|" + "Sales Tier" + "|" + biData[i]["row_id"]);
        coloredCells.current.push({ [ky1]: 2 });
        if (lstOfRowIdE3 === null || lstOfRowIdE3 === undefined || lstOfRowIdE3.trim() === "") {
          lstOfRowIdE3 = (i + 2 - 1).toString()
        } else {
          lstOfRowIdE3 = (lstOfRowIdE3 + "," + (i + 2 - 1).toString())
        }
      }
      iPK = ""
    }

    // console.log("error1 -> ", error1)
    // console.log("lstOfRowIdE1 -> ", lstOfRowIdE1)
    // console.log("error2 -> ", error2)
    // console.log("lstOfRowIdE2 -> ", lstOfRowIdE2)
    // console.log("error3 -> ", error3)
    // console.log("lstOfRowIdE3 -> ", lstOfRowIdE3)

    let validationMsg = ""
    if (error1 === 1) {
      yesMatched = true
      validationMsg = "Invalid 'From Sales Tier' and 'Sales Tier' for rows [" + lstOfRowIdE1 + "]."
    }
    if (error2 === 1) {
      yesMatched = true
      if (validationMsg === null || validationMsg === undefined || validationMsg.trim() === "") {
        validationMsg = "Invalid 'From Sales Tier' for rows [" + lstOfRowIdE2 + "]"
      } else {
        validationMsg = validationMsg + "\n" + "Invalid 'From Sales Tier' for rows [" + lstOfRowIdE2 + "]."
      }
    }
    if (error3 === 1) {
      yesMatched = true
      if (validationMsg === null || validationMsg === undefined || validationMsg.trim() === "") {
        validationMsg = "'Sales Tier' already exists for rows [" + lstOfRowIdE3 + "]"
      } else {
        validationMsg = validationMsg + "\n" + "'Sales Tier' already exists for rows [" + lstOfRowIdE3 + "]."
      }
    }
    // console.log("yesMatched -> ", yesMatched)
    // console.log("validationMsg -> ", validationMsg)
    if (yesMatched) {
      toastMessage(validationMsg, "warning");
    }
    return yesMatched;
  };
  const sizingCustomLogicForSizeColumn = (biData, szData) => {
    // console.log("biData -> ", biData)
    const szPk = ['Year', 'Season Code', 'Department', 'SKU', 'Location', 'Sales Tier'];
    let szPkWithSzMp = new Map();
    szData.map(rw => {
      let tmpConSzVal = sizesName.map(f => rw[f]).join(CH1);
      let tmpConPkVal = szPk.map(f => rw[f]).join("|");
      szPkWithSzMp.set(tmpConPkVal, tmpConSzVal);
    });
    // console.log("szPkWithSzMp -> ", szPkWithSzMp)
    const biPk = ['Year', 'Season Code', 'Department', 'SKU', 'Location', 'From Sales Tier'];
    let biPkWithSzMp = new Map();
    biData.map(rw => {
      let tmpConSzVal = sizesName.map(f => rw[f]).join(CH1);
      let tmpConPkVal = (biPk.map(f => rw[f]).join("|")) + CH2 + rw['Delivery'];
      biPkWithSzMp.set(tmpConPkVal, tmpConSzVal);
    });
    // console.log("biPkWithSzMp -> ", biPkWithSzMp)
    // console.log("biPkWithSzMp.size -> ", biPkWithSzMp.size)

    let updateSzMp = new Map()
    for (let [k, v] of biPkWithSzMp.entries()) {
      // console.log("k -> ", k)
      // console.log("v -> ", v)
      // console.log("k.split(CH2) -> ", k.split(CH2))
      // console.log("k.split(CH2)[0] -> ", k.split(CH2)[0])
      let biKy = k.split(CH2)[0]
      if (szPkWithSzMp.has(biKy)) {
        // console.log("biKy -> ", biKy)
        let szvArr = szPkWithSzMp.get(biKy).split(CH1)
        // console.log("szvArr -> ", szvArr)
        let bsvArr = biPkWithSzMp.get(k).split(CH1)
        // console.log("bsvArr -> ", bsvArr)
        let fValArr = Array(sizesName.length)
        for (let i = 0; i < szvArr.length; i++) {
          let sz_v1 = szvArr[i];
          let bi_v1 = bsvArr[i];
          sz_v1 = ((sz_v1 === null || sz_v1 === undefined || sz_v1.trim() === "") ? 0 : parseInt(sz_v1))
          bi_v1 = ((bi_v1 === null || bi_v1 === undefined || bi_v1.trim() === "") ? 0 : parseInt(bi_v1))
          let tmpVl = (sz_v1 - bi_v1)
          // console.log("tmpVl -> ", tmpVl)
          fValArr[i] = tmpVl;
        }
        // console.log("fValArr -> ", fValArr)

        if (szPkWithSzMp.has(biKy)) {
          szPkWithSzMp.delete(biKy)
          szPkWithSzMp.set(biKy, fValArr.join(CH1))
        }
        // console.log("szPkWithSzMp -> ", szPkWithSzMp)

        if (updateSzMp.has(biKy)) {
          updateSzMp.delete(biKy)
          updateSzMp.set(biKy, fValArr.join(CH1))
        } else {
          updateSzMp.set(biKy, fValArr.join(CH1))
        }
        // console.log("***")
      }
    }
    // console.log("updateSzMp -> ", updateSzMp)

    let pkArr = ['Year', 'Season Code', 'Department', 'SKU', 'Location']
    let populateSzMp = new Map()
    biData.map(rw => {
      let pkV1 = (pkArr.map(f => rw[f]).join("|")) + "|" + rw['Sales Tier'];
      let pkV2 = (pkArr.map(f => rw[f]).join("|")) + "|" + rw['From Sales Tier'];
      populateSzMp.set(pkV1, pkV2);
    });
    // console.log("populateSzMp -> ", populateSzMp)

    const colsToPopulate = ["Delivery Month", "Delivery Date/In DC", "In Store", "Category", "Subcategory", "Style", "Style Description", "Color Code", "Color Description", "Store Tier", "Doors Based on Store Tier", "Weeks Bought", "Commit Coding", "Commit Date", "Channels Purchasing", "Style Activity", "Size Scale", "Collection", "Fabric Program", "Date Unit Confirmation Sent", "X-fty Date Override", "Sizing Confirmed", "External ID", "PO Status", "Buy Sheet Total", "dtc_buy_sheet_row_id", "whsl_buy_sheet_row_id", "Factory", "Commit Ship Mode", "X-fty Date", "Delivery Status", "Current FOB", "Current FOB Status", "Costing Status"];
    // console.log("colsToPopulate -> ", colsToPopulate)

    //1769
    pkArr = ['Year', 'Season Code', 'Department', 'SKU', 'Location', 'Sales Tier']
    // console.log("activeTabGridApi -> ", activeTabGridApi)
    activeTabGridApi.forEachNode(nd => {
      // console.log("nd -> ", nd)
      let pkVal = pkArr.map(f => nd.data[f]).join("|");
      if (updateSzMp.has(pkVal)) {
        // console.log("nd -> ", nd)
        // console.log("nd.data -> ", nd.data)
        // console.log("pkVal -> ", pkVal)
        // console.log("IF-1")
        let szVlArr = updateSzMp.get(pkVal).split(CH1)
        // console.log("szVlArr -> ", szVlArr)
        for (let i = 0; i < sizesName.length; i++) {
          // nd.data[sizesName[i]] = szVlArr[i]
          // console.log("sizesName[i] -> ", sizesName[i])
          // console.log("szVlArr[i] -> ", szVlArr[i])
          nd.setDataValue((req_tbl + CH1 + sizesName[i]), szVlArr[i])
        }
      }

      if (populateSzMp.has(pkVal)) {
        // console.log("IF-2")
        activeTabGridApi.forEachNode(rw => {
          // console.log("rw -> ", rw)
          let plVal2 = (pkArr.map(f => rw.data[f]).join("|"))
          if (populateSzMp.get(pkVal) === plVal2) {
            // console.log("IF-3")
            // console.log("plVal2 -> ", plVal2)
            // console.log("nd -> ", nd)
            // console.log("rw -> ", rw)
            // console.log("rw.data -> ", rw.data)
            // console.log("nd.data -> ", nd.data)
            for (let i = 0; i < colsToPopulate.length; i++) {
              // console.log("colsToPopulate[i] -> ", colsToPopulate[i])
              console.log("rw.data[colsToPopulate[i]] -> ", rw.data[colsToPopulate[i]], " <+> nd.data[colsToPopulate[i]] -> ", nd.data[colsToPopulate[i]])
              // rw.data[colsToPopulate[i]] = nd.data[colsToPopulate[i]]
              nd.setDataValue((req_tbl + CH1 + colsToPopulate[i]), rw.data[colsToPopulate[i]])
            }
            // rw.data["Sizing Split"] = "YES"
            nd.setDataValue((req_tbl + CH1 + "Sizing Split"), "YES")
          }
        })
      }
    });
    activeTabGridApi.refreshCells({ force: true });
  };
  const sizingCustomLogicForSizingOverRideColumn = (stateData) => {
    // console.log("IN +-> sizingCustomLogicForSizingOverRideColumn")
  };
  const postSortingAfterImportSizing = () => {
    // console.log("IN +-> postSortingAfterImportSizing")
  };
  const setCustomValidationForSizing = (mode) => {
    // Bulk Import
    // Year|Season Code|Department|SKU|Location|Sales Tier|From Sales Tier
    // Sizing Override Import
    // Year|Season Code|Department|SKU|Location|Sales Tier

    // const tmpColDef = getColumnDefByTblName(importDataFromSheet);
    const tmpColDef = colDef;
    // console.log("setCustomValidationForSizing -> tmpColDef -> ", tmpColDef)
    for (let i = 0; i < tmpColDef.length; i++) {
      if (tmpColDef[i]["field"] === "From Sales Tier") {
        let vl = tmpColDef[i]["validation"]
        // console.log('vl -> ', vl)
        vl = vl.replace("|MANDATORY", "")
        // console.log('vl -> ', vl)
        if (mode === "Bulk Import") {
            vl = (vl + "|MANDATORY")
        }
        // console.log('vl -> ', vl)
        tmpColDef[i]["validation"] = vl
      }
    }
    setColDef(tmpColDef)
    // console.log("setCustomValidationForSizing -> tmpColDef -> ", tmpColDef)
  };
  const customModeSizingImport = (buttonMode) => {
    if (buttonMode === "Bulk Import") {
      sessionStorage.setItem("sizing_import_mode", "Bulk Import")
      for (let i = 0; i < dataTransferRules.length; i++) {
        if (dataTransferRules[i]["origin_data_name"] === importDataFromSheet &&
          dataTransferRules[i]["rule_name"] === "Sizing_Merge_Rule" &&
          dataTransferRules[i]["group_name"].toLowerCase() === sessionStorage.getItem("user_type").toLowerCase()) {
          // console.log('dataTransferRules[i]["row_id"] -> ', dataTransferRules[i]["row_id"])
          dataTransferRules[i]["add_row"] = "YES"
          dataTransferRules[i]["copy_value"] = "NO"
        }
      }
    } else if (buttonMode === "Sizing Override Import") {
      sessionStorage.setItem("sizing_import_mode", "Sizing Override Import")
      for (let i = 0; i < dataTransferRules.length; i++) {
        if (dataTransferRules[i]["origin_data_name"] === importDataFromSheet &&
          dataTransferRules[i]["rule_name"] === "Sizing_Merge_Rule" &&
          dataTransferRules[i]["group_name"].toLowerCase() === sessionStorage.getItem("user_type").toLowerCase()) {
          // console.log('dataTransferRules[i]["row_id"] -> ', dataTransferRules[i]["row_id"])
          dataTransferRules[i]["add_row"] = "NO"
          dataTransferRules[i]["copy_value"] = "YES"
        }
      }
    }
    setCustomValidationForSizing(buttonMode)
  };
  const onSplitImportClick = () => {
    console.log("onSplitImportClick -> S -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
    if (sessionStorage.getItem("user_type").toLowerCase() === "Planning".toLowerCase() ||
        sessionStorage.getItem("user_type").toLowerCase() === "Wholesale".toLowerCase() ||
        sessionStorage.getItem("user_type").toLowerCase() === "Global Planning".toLowerCase()) {
        toastMessage(("Split Import is disabled for '" + sessionStorage.getItem("user_type") + "'."), "warning")
        return
    }
    // custom_mode_sizing_import("Bulk Import")
    customModeSizingImport("Bulk Import")
    // let oneDtRule = GetDtRule(importDataFromSheet)
    // console.log("onSplitImportClick -> oneDtRule -> ", oneDtRule)
    onImportDataClick()
    console.log("onSplitImportClick -> E -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
  };
  const onSizingOverrideImportClick = () => {
    console.log("onSizingOverrideImportClick -> S -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))

    // custom_mode_sizing_import("Sizing Override Import")
    customModeSizingImport("Sizing Override Import")
    // let oneDtRule = GetDtRule(importDataFromSheet)
    // console.log("onSizingOverrideImportClick -> oneDtRule -> ", oneDtRule)

    onImportDataClick()
    console.log("onSizingOverrideImportClick -> E -> " + formatDateToMmDdYyyyHhMmSsAmPm(new Date()))
  };
  const onImportDataClick = () => {
    coloredCells.current = new Array()
    let properAreaData = checkSetArea()
    if (properAreaData === null || properAreaData === undefined || properAreaData.length === 0) {
      return
    }

    // Custom code for Sizing_Import
    if (sessionStorage.getItem("sizing_import_mode") === "Bulk Import") {
      const isPreValidated = preValidationForSizingImport(properAreaData);
      if (isPreValidated) {
        return;
      }
    }

    let dataValidatedMp = validateTabData(null, properAreaData)
    if (dataValidatedMp && dataValidatedMp.status === false) {
      gridApi.current.refreshCells({ force: true })
      toastMessage(dataValidatedMp.msg, "warning")
      return
    }
    let oneDtRule = GetDtRule(importDataFromSheet)
    console.log("oneDtRule", oneDtRule)
    if (Object.keys(oneDtRule).length === 0) {
      return;
    }
    const strOrgPKsCols = oneDtRule["origin_data_primary_key_columns_name"]
    const strDestPKsCols = oneDtRule["destination_data_primary_key_columns_name"]
    const OrgPKCols = strOrgPKsCols.split(PipeDel)
    const DestPKCols = strDestPKsCols.split(PipeDel)
    if (OrgPKCols.length !== DestPKCols.length) {
      toastMessage("Number of primary key columns must be same in Source and Destination.", "warning")
      return
    }
    const strOrgValueCols = oneDtRule["origin_insert_update_columns_name"]
    const strDestValueCols = oneDtRule["destination_insert_update_columns_name"]
    const OrgValCols = strOrgValueCols.split(PipeDel)
    const DestValCols = strDestValueCols.split(PipeDel)
    if (OrgValCols.length !== DestValCols.length) {
      toastMessage("Number of value columns must be same in Source and Destination.", "warning")
      return
    }
    let ciMpOrgPKCols = new Map()
    for (let i = 0; i < OrgPKCols.length; i++) {
      ciMpOrgPKCols.set(OrgPKCols[i], findColumnPosOnName(OrgPKCols[i], gridApi.current) + 1)
    }
    for (let [k, v] of ciMpOrgPKCols) {
      if (v === null || v === undefined || v === 0 || v < 0) {
        toastMessage(("'" + k + "' column not found in " + importDataFromSheet + " tab."), "warning")
        return
      }
    }
    let ciMpOrgValCols = new Map()
    for (let i = 0; i < OrgValCols.length; i++) {
      ciMpOrgValCols.set(OrgValCols[i], findColumnPosOnName(OrgValCols[i], gridApi.current) + 1)
    }
    for (let [k, v] of ciMpOrgValCols) {
      if (v === null || v === undefined || v === 0 || v < 0) {
        toastMessage(("'" + k + "' column not found in " + importDataFromSheet + " tab."), "warning")
        return
      }
    }
    let ciMpDestPKCols = new Map()
    for (let i = 0; i < DestPKCols.length; i++) {
      ciMpDestPKCols.set(DestPKCols[i], findColumnPosOnName(DestPKCols[i], activeTabGridApi) + 1)
    }
    for (let [k, v] of ciMpDestPKCols) {
      if (v === null || v === undefined || v === 0 || v < 0) {
        toastMessage(("'" + k + "' column not found in " + tabNm + " tab."), "warning")
        return
      }
    }
    let ciMpDestValCols = new Map()
    for (let i = 0; i < DestValCols.length; i++) {
      ciMpDestValCols.set(DestValCols[i], findColumnPosOnName(DestValCols[i], activeTabGridApi) + 1)
    }
    for (let [k, v] of ciMpDestValCols) {
      if (v === null || v === undefined || v === 0 || v < 0) {
        toastMessage(("'" + k + "' column not found in " + tabNm + " tab."), "warning")
        return
      }
    }
    const blnAddRow = getColValFromDtRule(oneDtRule, "add_row")
    if (blnAddRow) {
      for (let i = 0; i < OrgPKCols.length; i++) {
        if (OrgValCols.includes(OrgPKCols[i]) === false) {
          toastMessage(("'" + OrgPKCols[i] + "' column not found in Source update data column field."), "warning")
          return
        }
      }
      for (let i = 0; i < DestPKCols.length; i++) {
        if (DestValCols.includes(DestPKCols[i]) === false) {
          toastMessage(("'" + DestPKCols[i] + "' column not found in Destination update data column field."), "warning")
          return
        }
      }
    }
    let stateData = getStateDataByTblName(req_tbl);
    let dupSrcPkValIdxMap = verifyOriginDataForDuplicatePKs(OrgPKCols, properAreaData)
    if (dupSrcPkValIdxMap !== null && dupSrcPkValIdxMap !== undefined && dupSrcPkValIdxMap.size > 0) {
      toastMessage(("Duplicate values found for [" + OrgPKCols.join(', ') + "] columns value combination."), "warning")
      return
    }
    // Need to take Source to Target PK columns mapping based on sequence defined in rule
    // Need to take Source to Target UPDATE columns mapping based on sequence defined in rule
    let orgToDestPkMp = new Map()
    let orgToDestUpdateMp = new Map()
    for (let i = 0; i < OrgPKCols.length; i++) {
      orgToDestPkMp.set(OrgPKCols[i], DestPKCols[i])
    }
    for (let i = 0; i < OrgValCols.length; i++) {
      orgToDestUpdateMp.set(OrgValCols[i], DestValCols[i])
    }
    removeEntriesWithSameKeyAndValue(orgToDestUpdateMp)
    const blnCopyVal = getColValFromDtRule(oneDtRule, "copy_value")
    const blnDeleteRow = getColValFromDtRule(oneDtRule, "delete_row")
    let srcPkValMap = getOriginDataPKsWithIndex(OrgPKCols, properAreaData)
    let destPkValMap = getDestDataPKsWithIndex(DestPKCols)

    console.log("blnCopyVal", blnCopyVal);
    console.log("blnDeleteRow", blnDeleteRow);
    console.log("srcPkValMap", srcPkValMap);
    console.log("destPkValMap", destPkValMap);

    // return;
    // if (blnDeleteRow === true) {
    //   const deleteRowsList =[];
    //   for (let [k, v] of destPkValMap) {
    //     if (srcPkValMap.has(k) === false) {
    //       const delData = stateData[v];
    //       deleteRowsList.push(delData);
    //     }
    //   }
    //   if (deleteRowsList !== null && deleteRowsList !== undefined && deleteRowsList.length > 0) {
    //     activeTabGridApi.applyTransaction({ remove: deleteRowsList })
    //     activeTabGridApi.refreshCells({ force: true })
    //   }
    // }
    destPkValMap = getDestDataPKsWithIndex(DestPKCols)
    let destToOrgUpdateMp = new Map()
    for (let [k, v] of orgToDestUpdateMp) {
      destToOrgUpdateMp.set(v, k)
    }
    let allColsNmSet = new Set();
    activeTabGridApi.getColumnState().map(c => allColsNmSet.add(c.colId.split(CH1)[1]));

    const newRowsList = [];
    console.log("stateData", stateData)
    let isUpdate = false;
    for (let [k, v] of srcPkValMap) {
      if (destPkValMap.has(k)) {
        if (blnCopyVal) {
          const srcNode = gridApi.current.getDisplayedRowAtIndex(v)
          const trgtNode = stateData[destPkValMap.get(k)];
          
          for (let i = 0; i < DestValCols.length; i++) {
            const col = DestValCols[i];

            const srcClVl = (srcNode.data[col] === null || srcNode.data[col] === undefined || srcNode.data[col].toString().trim() === "") ? "" : srcNode.data[col]
            const trgtClVl = (trgtNode[col] === null || trgtNode[col] === undefined || trgtNode[col].toString().trim() === "") ? "" : trgtNode[col]
            if (srcClVl !== trgtClVl) {
              // trgtNode.setDataValue(req_tbl + CH1 + col, srcClVl)
              isUpdate = true;
              
              trgtNode[col] = srcClVl;
            }
          }
        }
      } else {
        if (blnAddRow) {
          let srcNode = gridApi.current.getDisplayedRowAtIndex(v)
          let dObj = new Object()
          Object.keys(srcNode.data).forEach(sk => {
            if (DestValCols.includes(sk)) {
              dObj["" + sk + ""] = srcNode.data[sk]
            } else {
              if (orgToDestUpdateMp.has(sk)) {
                dObj["" + orgToDestUpdateMp.get(sk) + ""] = srcNode.data[sk]
              }
            }
          })
          let irKys = new Set(Object.keys(dObj))
          for (let item of allColsNmSet) {
            if (irKys.has(item) === false) {
              dObj["" + item + ""] = null
            }
          }
          newRowsList.push(dObj)
          dObj = null
        }
      }
    }
    if (newRowsList !== null && newRowsList !== undefined && newRowsList.length > 0) {
      stateData = [...newRowsList, ...stateData];
      isUpdate = true;
      // console.log("newRowsList -> ", newRowsList);
      // console.log("tmp -> ", tmp);
      // activeTabGridApi.applyTransaction({ add: newRowsList, addIndex: 0 })
    }

    activeTabGridApi.refreshCells({ force: true })

    if (importDataFromSheet === "Sizing_Import") {
      if (sessionStorage.getItem("sizing_import_mode") === "Bulk Import") {
        sizingCustomLogicForSizeColumn(properAreaData, stateData);
      }
      if (sessionStorage.getItem("sizing_import_mode") === "Sizing Override Import") {
        sizingCustomLogicForSizingOverRideColumn(stateData);
      }
      postSortingAfterImportSizing();
    } else {
      sessionStorage.removeItem("sizing_import_mode")
      if(isUpdate) setStateDataByTblName(req_tbl, stateData);
    }
    
    // const gridNewData = new Array();
    // activeTabGridApi.forEachNode(nd => gridNewData.push(nd.data));
    // console.log("gridNewData -> ", gridNewData);
    // setStateDataByTblName(req_tbl, JSON.parse(JSON.stringify(gridNewData)));

    toastMessage(("Data imported successfully." + "\n" + "Please perform 'Send Changes' from '" + tabNm + "' tab."), "success")
    onImportDataCloseClick()
  };
  const removeEntriesWithSameKeyAndValue = (map) => {
    for (let [key, value] of map) {
      if (key === value) {
        map.delete(key);
      }
    }
  };
  const onResetClick = () => {
    let cd = colDef
    let tmpData = []
    let dObj = new Object();
    for (let cnt = 1; cnt <= maxBulkImportLimit; cnt++) {
      for (let i = 0; i < cd.length; i++) {
        dObj["" + cd[i]["field"] + ""] = null
      }
      tmpData.push(dObj)
      dObj = new Object()
    }
    setSrcData(tmpData)
    coloredCells.current = new Array()
    sessionStorage.setItem("sizing_import_mode", "")
  };
  const onCellKeyDown = (event) => {
    if (event.event.keyCode === 46) {
      const res = validateTabData(event, gridApi.current);
      if (res.status === false) {
        if (res && res.msg && res.msg !== "") {
          toastMessage(res.msg, "warning");
        }
        gridApi.current.refreshCells({ force: true, rowNodes: [event.node] });
      }
    }
  };
  const onCellValueCh = (event, from = "") => {
    let eventSource = event.source ? event.source : ""; // paste | edit | rangeService    
    if (eventSource && eventSource !== "") {
      const col = event.colDef.headerName;
      let oldValue = event.oldValue || "";
      const column = colDef.filter((cl) => cl.headerName === col);
      if (column && column.length > 0) {
        if (column[0].type === "date" && !oldValue.includes('/')) oldValue = ExcelDateToJsDate(oldValue)
      }
      if (from === "keyDown" || event.newValue !== oldValue) {
        const res = validateTabData(event, gridApi.current);
        const col = event.colDef.headerName;
        if (res.status === false) {
          event.data[col] = event.oldValue;
          if (res && res.msg && res.msg !== "") {
            if (eventSource === "edit") {
              toastMessage(res.msg, "warning");
            } else {
              if (!msgArr.current.includes(res.msg)) msgArr.current.push(res.msg)
            }
          }
        }
        gridApi.current.refreshCells({ force: true, rowNodes: [event.node] });
      }
    }
  };
  const onPasteEnd = () => {
    var msg = "";
    if (msgArr.current.length > 0) {
      msg = msgArr.current.join("\n\n")
      toastMessage(msg, "warning");
    }
    msgArr.current = [];
  };
  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' },
      ],
    };
  }, []);
  const defaultColDef = useMemo(() => {
    return {
      wrapHeaderText: true,
    };
  }, []);
  return (
    <MDBox>
      <div style={{ marginBottom: "8px", display: "flex", marginTop: "8px" }}>
        <div style={{ "width": "80%", display: "flex" }}>
          <MDButton size="small" variant="gradient" color="info" sx={{ textTransform: "none" }} onClick={() => onAddRowClick()}>Add Row</MDButton>
          <MDButton size="small" variant="gradient" color="info" sx={{ textTransform: "none", marginLeft: "10px" }} onClick={() => onDeleteRowClick()}>Delete Row</MDButton>
          <MDButton size="small" variant="gradient" color="info" sx={{ textTransform: "none", marginLeft: "10px" }} onClick={() => onSetAreaClick()}>1. Set Area</MDButton>
          {
            req_tbl === "xl_faherty_sizing"
                ?
                <div>
                  <MDButton size="small" variant="gradient" color="info" sx={{ textTransform: "none", marginLeft: "10px" }} onClick={() => onSplitImportClick()}>Split Import</MDButton>
                  <MDButton size="small" variant="gradient" color="info" sx={{ textTransform: "none", marginLeft: "10px" }} onClick={() => onSizingOverrideImportClick()}>Sizing Override Import</MDButton>
                </div>
                :
                <div>
                  <MDButton size="small" variant="gradient" color="info" sx={{ textTransform: "none", marginLeft: "10px" }} onClick={() => onImportDataClick()}>2. Import Data</MDButton>
                </div>
          }
        </div>
        <div style={{ "width": "20%", textAlign: "right" }}>
          <MDButton size="small" variant="gradient" color="info" sx={{ textTransform: "none", marginLeft: "10px" }} onClick={() => onResetClick()}>Reset</MDButton>
          <MDButton size="small" variant="gradient" color="info" sx={{ textTransform: "none", marginLeft: "10px" }} onClick={onImportDataCloseClick}>Close</MDButton>
        </div>
      </div>
      <div style={{ height: '575px', width: '100%', textAlign: 'left' }} className="ag-theme-quartz">
        <AgGridReact
          onGridReady={(event) => onGridReady(event)}
          onCellKeyDown={onCellKeyDown}
          onCellValueChanged={onCellValueCh}
          onPasteEnd={onPasteEnd}
          statusBar={statusBar}
          rowSelection="multiple"
          enableColResize={true}
          columnDefs={colDef}
          rowData={srcData}
          suppressRowClickSelection={true}
          suppressFieldDotNotation
          getRowHeight={25}
          suppressPropertyNamesCheck={true}
          enableRangeSelection={true}
          enterNavigatesVertically={true}
          enterNavigatesVerticallyAfterEdit={true}
          components={{ Datepicker: getDatePicker() }}
          suppressClipboardApi={true}
          allowContextMenuWithControlKey={true}
          headerHeight={45}
          defaultColDef={defaultColDef}
        />
      </div>
      <MDSnackbar
        color={SnackBarNotiColor}
        icon="check"
        title={applicationTitle + " App"}
        content={SnackBarNotiMsg}
        dateTime="1 sec ago"
        open={SnackBarNoti}
        onClose={closeSnackBarNoti}
        close={closeSnackBarNoti}
      />
    </MDBox>
  )
}
export default ImportData;